/* Loading State Styles */
.workspace-loading-container {
  @apply items-center animate-pulse py-48 sm:py-24 md:py-32 lg:py-48;
}

.workspace-loading-logo {
  @apply h-24 mx-auto w-auto;
}

.workspace-loading-text {
  @apply mt-0 text-center text-2xl font-bold text-gray-200;
}

/* Sidebar Styles */
.workspace-sidebar {
  @apply flex-shrink-0 h-full bg-gray-700/70;
}

.workspace-sidebar-inner {
  @apply bg-gray-700/70 border-t-purple-500 border-b-cyan-500 
         dark:bg-cyan-400 min-w-32 max-w-36 
         border-r-2 border-r-purple-600 h-full;
}

.workspace-sidebar-nav {
  @apply w-full justify-center h-full py-2;
}

/* Main Content Styles */
.workspace-main-container {
  @apply w-full h-full bg-gray-200 dark:bg-gray-700;
}

.workspace-main-content {
  @apply dark:bg-gray-800 inline overflow-y-scroll;
}

/* Settings Section Styles */
.workspace-settings-container {
  @apply mb-6 p-2 sm:align-middle;
}

.workspace-settings-inputs {
  @apply gap-2 mt-3 bg-gray-100 dark:bg-gray-600;
}

.workspace-settings-security-warning {
  @apply my-2 w-full text-center text-sm text-gray-600 
         border-4 px-2 py-2 border-red-300 rounded;
}

.workspace-settings-save-button {
  @apply whitespace-nowrap w-full items-center py-1 
         text-xl text-gray-200 rounded bg-purple-500 
         px-32 hover:bg-purple-400 
         focus:outline-none focus:ring-2 focus:ring-purple-600;
}

/* Permissions Section Styles */
.workspace-permissions-container {
  @apply bg-gray-300 dark:bg-gray-700 
         overflow-y-auto mb-2 border-b-2 border-b-purple-400 h-full;
}

.workspace-permissions-grid {
  @apply grid grid-cols-4 gap-2 mx-2 py-4 h-full;
}

.workspace-permissions-empty {
  @apply flex min-h-full text-gray-400 ~py-48/72 col-span-4;
}

.workspace-permissions-user-card {
  @apply flex text-xs rounded items-center 
         justify-between p-2 border-2 border-purple-400 
         cursor-pointer hover:bg-purple-500 hover:text-white 
         text-gray-600 dark:text-gray-200 w-full;
}

.workspace-permissions-user-remove-button {
  @apply text-2xs px-3 py-1 bg-gray-400 
         dark:bg-gray-500 dark:hover:bg-cyan-400 
         hover:bg-pink-500 text-white rounded-sm;
}

/* Invitations Section Styles */
.workspace-invitations-container {
  @apply flex flex-col h-full;
}

.workspace-invitations-list {
  @apply bg-gray-300 dark:bg-gray-700 h-full 
         mb-2 border-b-2 border-b-purple-400 py-48;
}

.workspace-invitations-grid {
  @apply grid grid-cols-4 gap-2 mx-2 py-4 h-full;
}

.workspace-invitations-empty {
  @apply text-gray-400 col-span-4 h-full;
}

.workspace-invitations-invite-card {
  @apply flex text-xs rounded items-center 
         justify-between p-2 border-2 border-pink-400 
         cursor-pointer hover:bg-pink-500 hover:text-white 
         text-gray-600 dark:text-gray-200;
}

.workspace-invitations-send-button {
  @apply w-full border-2 border-purple-400 
         bg-purple-500 hover:bg-purple-400 
         py-2 px-1 text-white rounded;
}

.workspace-invitations-sending-button {
  @apply w-full bg-gray-500 hover:bg-gray-400 
         py-2 px-1 text-white rounded 
         focus:outline-none
         animate-bounce;
}
.permissions-section-container {
  @apply bg-gray-300 dark:bg-gray-700 overflow-y-auto mb-2 border-b-2 border-b-purple-400 h-full;
}

.permissions-section-inner {
  @apply bg-gray-300 dark:bg-gray-700 overflow-y-auto;
}

.permissions-section-grid {
  @apply grid grid-cols-4 gap-2 mx-2 py-4 h-full;
}

.permissions-empty-state {
  @apply flex min-h-full text-gray-400 ~py-48/72 col-span-4;
}

.permissions-empty-state-content {
  @apply text-2xl w-full items-center text-center align-middle h-full p-6;
}

.permissions-empty-state-icon {
  @apply h-9 w-9 rounded mr-1 inline align-middle text-gray-400;
}

.permissions-member-card {
  @apply flex text-xs rounded items-center justify-between p-2 border-2 border-purple-400 cursor-pointer hover:bg-purple-500 hover:text-white text-gray-600 dark:text-gray-200 w-full;
}

.permissions-member-content {
  @apply text-xs flex items-center;
}

.permissions-member-image {
  @apply w-6 h-6 rounded inline mr-2;
}

.permissions-member-remove-button {
  @apply text-2xs px-3 py-1 bg-gray-400 dark:bg-gray-500 dark:hover:bg-cyan-400 hover:bg-pink-500 text-white rounded-sm;
}
.settings-section-container {
  @apply mb-6 p-2 sm:items-center;
}

.settings-section-input-wrapper {
  @apply gap-2 mt-3 bg-gray-100 dark:bg-gray-600;
}

.settings-section-input-text {
  @apply text-gray-200 dark:bg-gray-600;
}

.settings-section-grid {
  @apply grid grid-cols-12 gap-2 mt-6;
}

.settings-section-security-segment {
  @apply col-span-4 bg-gray-100 dark:bg-gray-600;
}

.settings-section-security-token {
  @apply col-span-8 bg-gray-100 dark:bg-gray-600 text-gray-200;
}

.settings-section-alert {
  @apply my-2 w-full text-center text-sm text-gray-600 border-4 px-2 py-2 border-red-300 rounded;
}

.settings-section-alert-icon {
  @apply h-6 w-6 inline text-pink-500 rounded;
}

.settings-section-alert-text {
  @apply pl-3 text-pink-500;
}

.settings-section-save-button {
  @apply whitespace-nowrap w-full items-center py-1 text-xl text-gray-200 rounded bg-purple-500 px-32 hover:bg-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-600;
}

.settings-section-save-icon {
  @apply h-8 w-8 mr-2 inline;
}
