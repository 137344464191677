.theme-toggler-button {
  border-radius: 9999px;
  padding: .5rem;
}

.theme-toggler-button:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.theme-icon-light {
  --tw-text-opacity: 1;
  width: 2rem;
  height: 1.5rem;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.theme-icon-light:hover {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}

.theme-icon-dark {
  --tw-text-opacity: 1;
  width: 2rem;
  height: 1.5rem;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.theme-icon-dark:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.theme-icon-system {
  --tw-text-opacity: 1;
  width: 2rem;
  height: 1.5rem;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.theme-icon-system:hover {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity, 1));
}

.nav-container {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
  background-color: #0000;
  width: 100%;
}

.nav-container:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.nav-wrapper {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.nav-content {
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  padding-right: .5rem;
  display: flex;
}

.nav-logo-container {
  align-items: center;
  display: flex;
}

.nav-logo {
  height: 2.5rem;
  padding-right: .25rem;
  display: inline;
}

.nav-logo-text {
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: 1.875rem;
  line-height: 2.25rem;
  display: inline;
}

.nav-version-badge {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
  vertical-align: sub;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .125rem;
  margin-left: .25rem;
  margin-right: .25rem;
  padding: .125rem .25rem;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: .625rem;
  font-weight: 300;
  line-height: 1rem;
  display: inline;
}

.nav-desktop-menu {
  display: none;
}

@media (width >= 48rem) {
  .nav-desktop-menu {
    display: block;
  }
}

.nav-desktop-links {
  align-items: baseline;
  margin-left: 2.5rem;
  display: flex;
}

.nav-link {
  padding: .25rem .75rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.nav-link-current {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.nav-link-current:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.nav-link-inactive {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.nav-link-inactive:hover {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
}

.nav-link-inactive:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.nav-link-icon {
  width: 1.5rem;
  height: 1.5rem;
  padding-bottom: .25rem;
  display: inline;
}

.nav-desktop-right {
  display: none;
}

@media (width >= 48rem) {
  .nav-desktop-right {
    display: block;
  }
}

.nav-docs-container {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-weight: 500;
  display: flex;
}

.nav-docs-container:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.nav-docs-text {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem 1rem;
}

.nav-docs-text:hover {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity, 1));
}

.nav-docs-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.nav-docs-text:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.nav-profile-button {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  background-color: #0000;
  border-radius: 9999px;
}

.nav-profile-button:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.nav-profile-button:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.nav-profile-image {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
}

.nav-profile-image:hover {
  width: 2.5rem;
  height: 2.5rem;
}

.nav-dropdown-menu {
  transform-origin: 100% 0;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  text-align: center;
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  width: auto;
  min-width: 9rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
  --tw-ring-opacity: .05;
  border-radius: .125rem;
  margin-top: .5rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  position: absolute;
  right: 0;
}

.nav-dropdown-menu:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.nav-dropdown-menu:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.nav-dropdown-item {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
  display: block;
  overflow: hidden;
}

.nav-dropdown-item:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.nav-dropdown-item-active {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.nav-mobile-button:hover {
  background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .75;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.nav-mobile-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #22d3ee;
  outline: 2px solid #0000;
}

.nav-mobile-panel {
  z-index: 50;
}

@media (width >= 48rem) {
  .nav-mobile-panel {
    display: none;
  }
}

.nav-mobile-links {
  padding: .5rem .5rem .75rem;
}

@media (width >= 40rem) {
  .nav-mobile-links {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

.nav-mobile-link {
  border-radius: .375rem;
  padding: .5rem .75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: block;
}

.nav-mobile-link-current {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.nav-mobile-link-inactive {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.nav-mobile-link-inactive:hover {
  background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .75;
}

.nav-mobile-user-section {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  padding-top: 1rem;
  padding-bottom: .75rem;
}

.nav-mobile-user-info {
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
}

.nav-mobile-user-name {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.nav-mobile-user-email {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.nav-mobile-submenu {
  z-index: 50;
  margin-top: .75rem;
}

.nav-mobile-submenu > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.nav-mobile-submenu {
  padding-left: .5rem;
  padding-right: .5rem;
}

.nav-mobile-submenu-item {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  padding: .5rem .75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: block;
}

.nav-mobile-submenu-item:hover {
  background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .75;
}

.footer-container {
  --tw-border-opacity: 1;
  border-top-width: 2px;
  border-top-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
  width: 100%;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  overflow: hidden;
}

.footer-container:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

@media (width >= 40rem) {
  .footer-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 64rem) {
  .footer-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.footer-content {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.footer-text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  line-height: 1.5rem;
}

.footer-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.action-button-container {
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  padding: .25rem .75rem;
  display: inline;
}

.action-button-container:hover {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity, 1));
}

.action-button {
  white-space: nowrap;
}

.action-button:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.action-button-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .25rem;
  display: inline;
}

.env-dropdown-container {
  z-index: 40;
  position: relative;
}

.env-dropdown-button {
  width: 100%;
  position: relative;
}

@keyframes color-change-2x {
  0% {
    background: #f3f4f6;
  }

  to {
    background: #e5e7eb;
  }
}

.env-dropdown-button {
  cursor: default;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
  text-align: left;
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  padding: .5rem 2rem .5rem .75rem;
  animation: 4s linear infinite alternate both color-change-2x;
}

.env-dropdown-button:focus {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity, 1));
  outline: 2px solid #0000;
}

@keyframes color-change-2x-dark {
  0% {
    background: #374151;
  }

  to {
    background: #1f2937;
  }
}

.env-dropdown-button:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 40rem) {
  .env-dropdown-button {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.env-dropdown-button-text {
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.5rem;
  display: block;
}

.env-dropdown-button-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.env-dropdown-button-icon-wrapper {
  pointer-events: none;
  align-items: center;
  padding-right: .5rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.env-dropdown-button-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.env-dropdown-button-icon:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.env-dropdown-options {
  z-index: 50;
  vertical-align: middle;
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  width: 100%;
  max-height: 15rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
  --tw-ring-opacity: .05;
  border-radius: .375rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 1rem;
  line-height: 1.75rem;
  animation: 4s linear infinite alternate both color-change-2x;
  position: absolute;
  overflow: visible;
}

.env-dropdown-options:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.env-dropdown-options:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 40rem) {
  .env-dropdown-options {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.env-dropdown-option {
  cursor: default;
  user-select: none;
  padding: .5rem 2.25rem .5rem .75rem;
  position: relative;
}

.env-dropdown-option-active {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.env-dropdown-option-active:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.env-dropdown-option-inactive {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.env-dropdown-option-inactive:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.env-dropdown-option-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.env-dropdown-option-text-selected {
  font-weight: 600;
}

.env-dropdown-option-text-unselected {
  font-weight: 400;
}

.env-dropdown-option-check-wrapper {
  align-items: center;
  padding-right: 1rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.env-dropdown-option-check-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.env-dropdown-option-check-icon-active {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.env-dropdown-option-check-icon-active:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.env-dropdown-option-check-icon-inactive {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.env-select-modal-container {
  z-index: 30;
  font-size: .875rem;
  line-height: 1.5rem;
  position: fixed;
  inset: 0;
  overflow-y: visible;
}

.env-select-modal-wrapper {
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  min-height: 100vh;
  padding: 1rem 1rem 5rem;
  display: flex;
}

@media (width >= 40rem) {
  .env-select-modal-wrapper {
    padding: 0;
    display: block;
  }
}

.env-select-modal-overlay {
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .75;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  inset: 0;
}

.env-select-modal-spacer {
  display: none;
}

@media (width >= 40rem) {
  .env-select-modal-spacer {
    vertical-align: middle;
    height: 100vh;
    display: inline-block;
  }
}

.env-select-modal-content {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  text-align: left;
  vertical-align: bottom;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding-top: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation: 4s linear infinite alternate both color-change-2x;
  display: inline-block;
  position: relative;
  overflow: visible;
}

.env-select-modal-content:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 40rem) {
  .env-select-modal-content {
    vertical-align: middle;
    width: 100%;
    max-width: 32rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
  }
}

.env-select-modal-header {
  padding-left: .25rem;
  padding-right: .25rem;
  display: inline-flex;
}

.env-select-modal-icon {
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  animation: 4s linear infinite alternate both color-change-2x;
}

.env-select-modal-icon:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

.env-select-modal-title {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding-left: .5rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
}

.env-select-modal-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.env-select-modal-close-button {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .375rem;
  justify-content: center;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.env-select-modal-close-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
}

.env-select-modal-close-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.env-select-modal-close-button:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .env-select-modal-close-button {
    grid-column-start: 1;
    margin-top: 0;
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.inner-nav-button-container {
  margin-left: .25rem;
  margin-right: .25rem;
}

.inner-nav-button {
  white-space: nowrap;
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-weight: 500;
  display: inline;
}

.inner-nav-button:hover {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity, 1));
}

.inner-nav-button:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.inner-nav-button-icon {
  width: 1.5rem;
  height: 1.5rem;
  padding-right: .25rem;
  display: inline;
}

.inner-nav-button-selected-container {
  padding-left: .25rem;
  padding-right: .25rem;
}

.inner-nav-button-selected {
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(192 132 252 / var(--tw-text-opacity, 1));
  padding: .25rem 0;
  font-weight: 500;
  display: inline;
}

.inner-nav-button-selected:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.inner-nav-button-selected-icon {
  width: 1.5rem;
  height: 1.5rem;
  padding-right: .25rem;
  display: inline;
}

.page-heading-container {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 1.5rem;
}

.page-heading-text {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  font-weight: 600;
  line-height: 1.5rem;
}

.workspace-dropdown-container {
  z-index: 10;
  position: relative;
}

.workspace-dropdown-button {
  cursor: default;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
  text-align: left;
  vertical-align: middle;
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  padding: .5rem 2rem .5rem .75rem;
  animation: 4s linear infinite alternate both color-change-2x;
  position: relative;
}

.workspace-dropdown-button:focus {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity, 1));
  outline: 2px solid #0000;
}

.workspace-dropdown-button:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 40rem) {
  .workspace-dropdown-button {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.workspace-dropdown-button-text {
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.5rem;
  display: block;
  overflow-x: hidden;
}

.workspace-dropdown-button-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.workspace-dropdown-button-icon-wrapper {
  pointer-events: none;
  align-items: center;
  padding-right: .5rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.workspace-dropdown-button-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.workspace-dropdown-button-icon:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.workspace-dropdown-options {
  vertical-align: middle;
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  width: 100%;
  max-height: 15rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
  --tw-ring-opacity: .05;
  border-radius: .375rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 1rem;
  line-height: 1.75rem;
  animation: 4s linear infinite alternate both color-change-2x;
  position: absolute;
  overflow: hidden;
}

.workspace-dropdown-options:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.workspace-dropdown-options:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 40rem) {
  .workspace-dropdown-options {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.workspace-dropdown-option {
  cursor: default;
  user-select: none;
  padding: .5rem 2.25rem .5rem .75rem;
  position: relative;
}

.workspace-dropdown-option-active {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.workspace-dropdown-option-active:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.workspace-dropdown-option-inactive {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.workspace-dropdown-option-inactive:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.workspace-dropdown-option-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.workspace-dropdown-option-text-selected {
  font-weight: 600;
}

.workspace-dropdown-option-text-unselected {
  font-weight: 400;
}

.workspace-dropdown-option-check-wrapper {
  align-items: center;
  padding-right: 1rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.workspace-dropdown-option-check-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.workspace-dropdown-option-check-icon-active {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.workspace-dropdown-option-check-icon-active:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.workspace-dropdown-option-check-icon-inactive {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.workspace-select-modal-container {
  z-index: 10;
  font-size: .875rem;
  line-height: 1.5rem;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.workspace-select-modal-wrapper {
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  min-height: 100vh;
  padding: 1rem 1rem 5rem;
  display: flex;
}

@media (width >= 40rem) {
  .workspace-select-modal-wrapper {
    padding: 0;
    display: block;
  }
}

.workspace-select-modal-overlay {
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .75;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  inset: 0;
}

.workspace-select-modal-spacer {
  display: none;
}

@media (width >= 40rem) {
  .workspace-select-modal-spacer {
    vertical-align: middle;
    height: 100vh;
    display: inline-block;
  }
}

.workspace-select-modal-content {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  text-align: left;
  vertical-align: bottom;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding-top: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation: 4s linear infinite alternate both color-change-2x;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.workspace-select-modal-content:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 40rem) {
  .workspace-select-modal-content {
    vertical-align: middle;
    width: 100%;
    max-width: 32rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
  }
}

.workspace-select-modal-header {
  padding-left: .25rem;
  padding-right: .25rem;
  display: inline-flex;
}

.workspace-select-modal-icon {
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  animation: 4s linear infinite alternate both color-change-2x;
}

.workspace-select-modal-icon:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

.workspace-select-modal-title {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding-left: .5rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
}

.workspace-select-modal-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.workspace-select-modal-close-button {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .375rem;
  justify-content: center;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.workspace-select-modal-close-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
}

.workspace-select-modal-close-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.workspace-select-modal-close-button:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .workspace-select-modal-close-button {
    grid-column-start: 1;
    margin-top: 0;
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.loading-container {
  align-items: center;
  padding: 12rem;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@media (width >= 40rem) {
  .loading-container {
    padding: 6rem;
  }
}

@media (width >= 48rem) {
  .loading-container {
    padding: 8rem;
  }
}

@media (width >= 64rem) {
  .loading-container {
    padding: 12rem;
  }
}

.workspace-modal {
  z-index: 50;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.workspace-modal-container {
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  min-height: 100vh;
  padding: 1rem 1rem 5rem;
  display: flex;
}

@media (width >= 40rem) {
  .workspace-modal-container {
    padding: 0;
    display: block;
  }
}

.workspace-modal-overlay {
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .75;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  inset: 0;
}

.workspace-modal-spacer {
  display: none;
}

@media (width >= 40rem) {
  .workspace-modal-spacer {
    vertical-align: middle;
    height: 100vh;
    display: inline-block;
  }
}

.workspace-modal-content {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  text-align: left;
  vertical-align: bottom;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: 1.25rem 1rem 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.workspace-modal-content:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

@media (width >= 40rem) {
  .workspace-modal-content {
    vertical-align: middle;
    width: 100%;
    max-width: 32rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
  }
}

.workspace-modal-header {
  align-items: center;
  margin-bottom: 1.5rem;
  display: flex;
}

.workspace-modal-header > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.workspace-modal-icon {
  --tw-text-opacity: 1;
  width: 1.5rem;
  height: 1.5rem;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.workspace-modal-title {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.workspace-modal-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.workspace-modal-dropdown {
  margin-top: 1rem;
}

.workspace-modal-footer {
  justify-content: flex-end;
  margin-top: 1.5rem;
  display: flex;
}

.workspace-modal-close-button {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-color: #0000;
  border-radius: .375rem;
  justify-content: center;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.workspace-modal-close-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity, 1));
}

.workspace-modal-close-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .workspace-modal-close-button {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.dashboard-container {
  background-color: #374151b3;
  width: 100%;
  height: 100%;
  display: flex;
}

.dashboard-sidebar {
  border-right-width: 2px;
  border-top-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
  border-bottom-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 1;
  border-right-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
  background-color: #374151b3;
  min-width: 9rem;
  max-width: 11rem;
  height: 100%;
}

.dashboard-sidebar:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.dashboard-sidebar-title {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  padding: .25rem;
}

.dashboard-sidebar-chart-buttons {
  text-align: center;
  width: 100%;
  height: 100%;
}

.dashboard-chart-button {
  padding: .25rem 1rem;
}

.dashboard-chart-button:hover {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
}

.dashboard-chart-button-active {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.dashboard-main-content {
  --tw-text-opacity: 1;
  width: 100%;
  height: 100%;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  animation: 4s linear infinite alternate both color-change-2x;
}

.dashboard-main-content:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

.dashboard-loading-container {
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}

@media (width >= 40rem) {
  .dashboard-loading-container {
    padding-top: 3rem;
  }
}

@media (width >= 48rem) {
  .dashboard-loading-container {
    padding-top: 6rem;
  }
}

@media (width >= 64rem) {
  .dashboard-loading-container {
    padding-top: 8rem;
  }
}

@media (width >= 80rem) {
  .dashboard-loading-container {
    padding-top: 12rem;
  }
}

.dashboard-loading-content {
  align-items: center;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.dashboard-loading-logo {
  width: auto;
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-loading-text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.dashboard-empty-state {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.dashboard-empty-state:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.dashboard-empty-state-title {
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (width >= 40rem) {
  .dashboard-empty-state-title {
    padding-top: 3.5rem;
  }
}

@media (width >= 48rem) {
  .dashboard-empty-state-title {
    padding-top: 6rem;
  }
}

@media (width >= 64rem) {
  .dashboard-empty-state-title {
    padding-top: 8rem;
  }
}

.dashboard-empty-state-description {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.tunnels-container {
  background-color: #374151b3;
  width: 100%;
  height: 100%;
  display: flex;
}

.sidebar {
  border-right-width: 2px;
  border-top-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
  border-bottom-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 1;
  border-right-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
  background-color: #374151b3;
  min-width: 8rem;
  max-width: 9rem;
  height: 100%;
}

.sidebar:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.sidebar-content {
  place-items: flex-start start;
}

.sidebar-content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.sidebar-content {
  padding: .25rem .5rem;
}

.sidebar-buttons {
  min-width: 9rem;
  max-width: 11rem;
}

.loading-container {
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 12rem;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@media (width >= 40rem) {
  .loading-container {
    padding: 6rem;
  }
}

@media (width >= 48rem) {
  .loading-container {
    padding: 8rem;
  }
}

@media (width >= 64rem) {
  .loading-container {
    padding: 12rem;
  }
}

.loading-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.no-env-container {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.no-env-message {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  text-align: center;
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: 1.5rem;
}

.no-env-message:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.no-env-title {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

.no-env-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.no-env-text {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-top: .5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.no-env-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.tunnel-card-connect {
  cursor: auto;
  position: relative;
}

.tunnel-action-button-back {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin: .5rem;
  padding: .25rem .5rem;
  font-size: .8125rem;
  font-weight: 700;
  line-height: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
}

.tunnel-action-button-back:hover {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.new-tunnel-container {
  width: 100%;
  height: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
  animation: 4s linear infinite alternate both color-change-2x;
}

.new-tunnel-container:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 40rem) {
  .new-tunnel-container {
    padding-top: 1.25rem;
  }
}

.new-tunnel-form {
  cursor: pointer;
  width: 100%;
}

.form-section {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.form-button-container {
  width: 100%;
  padding-top: .25rem;
  padding-bottom: .5rem;
}

.create-button {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding: .5rem 1rem;
  font-weight: 700;
}

.create-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.disabled-button {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding: .5rem 1rem;
  font-weight: 700;
}

.disabled-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity, 1));
}

.tunnels-grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: .5rem;
  width: 100%;
  padding: 1rem;
  animation: 4s linear infinite alternate both color-change-2x;
  display: grid;
}

.tunnels-grid:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 40rem) {
  .tunnels-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 64rem) {
  .tunnels-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.tunnel-card {
  border-width: 2px;
  border-radius: .25rem;
  height: 15rem;
  position: relative;
}

.tunnel-card:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.tunnel-card-content {
  cursor: auto;
  padding-left: .5rem;
  padding-right: .5rem;
}

.tunnel-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  overflow: hidden;
}

.tunnel-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.tunnel-action-button {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin: .5rem;
  padding: .25rem .5rem;
  font-size: .8125rem;
  font-weight: 700;
  line-height: 1.5rem;
  position: absolute;
  top: 0;
}

.tunnel-action-button:hover {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.tunnel-action-button-key {
  right: 2rem;
}

.tunnel-action-button-delete {
  right: 0;
}

.tunnel-action-button-delete:hover {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
}

.tunnel-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.tunnel-state {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  font-size: .8125rem;
  line-height: 1.5rem;
}

.tunnel-info-section > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-style: solid;
}

.tunnel-info-content {
  text-align: center;
}

.tunnel-info-text {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  font-size: .8125rem;
  line-height: 1.5rem;
}

.tunnel-info-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.tunnel-info-value {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.tunnel-details {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: .8125rem;
  line-height: 1.5rem;
}

.tunnel-details:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.tunnel-details-value {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.5rem;
}

.tunnel-details-value:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.tunnel-protocol-text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: .8125rem;
  line-height: 1.5rem;
}

.tunnel-protocol-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.tunnel-protocol-value {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.5rem;
}

.tunnel-protocol-value:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.tunnel-psk {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  font-size: .8125rem;
  line-height: 1.5rem;
}

.tunnel-psk:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.tunnel-psk-value {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  font-size: .8125rem;
  line-height: 1.5rem;
}

.tunnel-psk-value:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.tunnel-connect-container {
  text-align: center;
  align-items: center;
  width: 18rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.tunnel-connect-header {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  align-items: center;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline;
}

.tunnel-connect-header:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.tunnel-connect-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  overflow: hidden;
}

.tunnel-connect-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.tunnel-connect-back {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin: .5rem;
  font-size: .8125rem;
  font-weight: 700;
  line-height: 1.5rem;
  position: absolute;
  top: 0;
  right: 2rem;
}

.tunnel-connect-back:hover {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.tunnel-connect-delete {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  margin: .5rem;
  padding: 0;
  font-size: .8125rem;
  line-height: 1.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.tunnel-connect-delete-icon, .tunnel-connect-back-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: inline;
}

.advanced-config {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.advanced-config-title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding-top: .25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.advanced-config-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.advanced-config-button {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-top: .5rem;
  padding: .5rem 1rem;
  font-weight: 700;
  display: inline;
}

.advanced-config-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
}

.advanced-config-button:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.simple-config {
  --tw-text-opacity: 1;
  width: 18rem;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.simple-config:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.simple-config-title {
  padding-top: .25rem;
}

.simple-config-description {
  text-wrap: wrap;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding-top: 1rem;
  font-size: .875rem;
  line-height: 1.5rem;
  display: inline;
}

.simple-config-description:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.simple-config-button-wrapper {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  padding-top: .25rem;
  font-size: .875rem;
  line-height: 1.5rem;
  display: inline;
}

.simple-config-button {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  margin-top: .5rem;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .875rem;
  line-height: 1.5rem;
  display: inline;
}

.simple-config-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.simple-config-button:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.modal-container {
  z-index: 40;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.modal-wrapper {
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem 1rem 5rem;
  display: flex;
}

@media (width >= 40rem) {
  .modal-wrapper {
    padding: 0;
    display: block;
  }
}

.modal-overlay {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .35;
  position: fixed;
  inset: 0;
}

.modal-content {
  width: 75%;
  height: 75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation: 4s linear infinite alternate both color-change-2x;
  display: inline-block;
  overflow: hidden;
}

.modal-content:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 40rem) {
  .modal-content {
    vertical-align: middle;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.modal-header {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  align-items: flex-start;
  padding-bottom: 1rem;
  display: flex;
}

.modal-header-icon {
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
}

.modal-title {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-left: .75rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
}

.modal-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.templates-grid {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
  border-radius: .25rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: .5rem;
  height: 20rem;
  margin-top: .5rem;
  padding: 1rem;
  display: grid;
  overflow-y: auto;
}

.templates-grid:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.template-card {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
  border-radius: .375rem;
  width: 100%;
  height: 6rem;
  padding: .5rem;
}

.template-card:hover {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
}

.template-card:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.template-header {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
  align-items: center;
  padding-bottom: .25rem;
  display: flex;
}

.template-icon-wrapper {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  padding: .25rem;
}

.template-name {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
  margin-left: .5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.template-name:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.flow-container {
  justify-content: space-between;
  align-items: center;
  margin-top: .5rem;
  display: flex;
}

.flow-steps {
  align-items: center;
  display: flex;
}

.flow-steps > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.flow-step {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .625rem;
  line-height: 1rem;
}

.flow-step:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.flow-step-end {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .625rem;
  line-height: 1rem;
}

.flow-step-end:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.flow-arrow {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1rem;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.apply-button {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding: 0 .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.apply-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
}

.cancel-button {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.cancel-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.cancel-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.cancel-button:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.save-button {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-width: 1px;
  border-color: #0000;
  border-radius: .375rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.save-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity, 1));
}

.save-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.modal-footer {
  justify-content: flex-end;
  display: flex;
}

.modal-footer > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.modal-footer {
  padding: 1rem 1.5rem;
}

.heading {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.heading:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
}

.toggle-switch {
  cursor: pointer;
  border-width: 2px;
  border-color: #0000;
  border-radius: 9999px;
  flex-shrink: 0;
  width: 1.75rem;
  height: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
  position: relative;
}

.toggle-switch:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.toggle-switch-enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.toggle-switch-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.toggle-handle {
  pointer-events: none;
  width: .5rem;
  height: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  border-radius: 9999px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.toggle-handle-enabled {
  --tw-translate-x: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.toggle-handle-disabled {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.dropdown-container {
  width: 100%;
  padding-left: .25rem;
  padding-right: .25rem;
  position: relative;
}

.dropdown-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-top: .125rem;
  margin-right: .25rem;
}

.dropdown-caret {
  width: 1rem;
  height: 1rem;
  margin-top: .25rem;
  margin-left: .125rem;
  margin-right: -.25rem;
}

.dropdown-menu {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  width: 11rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity, 1));
  --tw-ring-opacity: .05;
  border-radius: .125rem;
  margin-top: .5rem;
  position: absolute;
}

.dropdown-menu-content {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.dropdown-item {
  text-align: left;
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 1.5rem;
  display: block;
}

.dropdown-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.dropdown-item:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.dropdown-button {
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  background-color: #0000;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  display: inline-flex;
}

.dropdown-button:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.subnav-container {
  background-color: #374151b3;
  flex-shrink: 0;
  height: 100%;
}

.subnav-wrapper {
  border-right-width: 2px;
  border-right-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
  border-top-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  background-color: #374151b3;
  min-width: 9rem;
  max-width: 11rem;
  height: 100%;
}

.subnav-wrapper:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.subnav-buttons-container {
  place-items: flex-start start;
}

.subnav-buttons-container > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.subnav-buttons-container {
  padding: .25rem .5rem;
}

.subnav-divider {
  --tw-border-opacity: 1;
  border-left-width: 2px;
  border-left-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  place-items: start;
  padding: .5rem .5rem .25rem;
}

.subnav-divider:is(.dark *) {
  --tw-border-opacity: 1;
  border-left-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.subnav-divider-small {
  --tw-border-opacity: 1;
  border-left-width: 2px;
  border-left-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  place-items: start;
  padding-bottom: .25rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

.subnav-divider-small:is(.dark *) {
  --tw-border-opacity: 1;
  border-left-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.sftp-modal-body {
  padding: 1.25rem 1rem 1rem;
  animation: 4s linear infinite alternate both color-change-2x;
}

@media (width >= 40rem) {
  .sftp-modal-body {
    padding: 1.5rem 1.5rem 1rem;
  }

  .sftp-modal-header {
    align-items: flex-start;
    display: flex;
  }
}

.sftp-modal-icon-wrapper {
  border-radius: 9999px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  margin-left: auto;
  margin-right: auto;
  animation: 4s linear infinite alternate both color-change-2x;
  display: flex;
}

@media (width >= 40rem) {
  .sftp-modal-icon-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.sftp-modal-icon {
  --tw-text-opacity: 1;
  width: 2.25rem;
  height: 2.25rem;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.sftp-modal-title-wrapper {
  text-align: center;
  margin-top: .75rem;
}

@media (width >= 40rem) {
  .sftp-modal-title-wrapper {
    text-align: left;
    margin-top: 0;
    margin-left: 1rem;
  }
}

.sftp-modal-title {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  margin-top: .5rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.sftp-form-container {
  margin-top: 1.25rem;
}

.sftp-form-container > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.sftp-textarea-wrapper {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  padding: .5rem .75rem;
  position: relative;
}

.sftp-textarea-wrapper:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity, 1));
}

.sftp-textarea-label {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  margin-top: -1px;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-block;
  position: absolute;
  top: -.5rem;
  left: .5rem;
}

.sftp-textarea {
  --tw-text-opacity: 1;
  width: 100%;
  height: auto;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  background-color: #0000;
  border-width: 0;
  padding: 0;
  display: block;
}

.sftp-textarea::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(165 243 252 / var(--tw-placeholder-opacity, 1));
}

.sftp-textarea:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media (width >= 40rem) {
  .sftp-textarea {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.sftp-modal-footer {
  padding: .75rem 1rem;
  animation: 4s linear infinite alternate both color-change-2x;
}

@media (width >= 40rem) {
  .sftp-modal-footer {
    flex-direction: row-reverse;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
  }
}

.sftp-save-button {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-color: #0000;
  border-radius: .375rem;
  justify-content: center;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.sftp-save-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity, 1));
}

.sftp-save-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .sftp-save-button {
    width: auto;
    margin-left: .75rem;
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.sftp-cancel-button {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-radius: .375rem;
  justify-content: center;
  margin-top: .75rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.sftp-cancel-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.sftp-cancel-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .sftp-cancel-button {
    width: auto;
    margin-top: 0;
    margin-left: .75rem;
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.target-modal-container {
  z-index: 10;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.target-modal-wrapper {
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  min-height: 100vh;
  padding: 1rem 1rem 5rem;
  display: flex;
}

@media (width >= 40rem) {
  .target-modal-wrapper {
    padding: 0;
    display: block;
  }
}

.target-modal-overlay {
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .75;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  inset: 0;
}

.target-modal-content {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  text-align: left;
  vertical-align: bottom;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: 1.25rem 1rem 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
  position: relative;
  overflow: hidden;
}

@media (width >= 40rem) {
  .target-modal-content {
    vertical-align: middle;
    width: 100%;
    max-width: 32rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
  }
}

.target-modal-header {
  padding-bottom: 1.5rem;
  padding-left: .25rem;
  padding-right: .25rem;
  display: inline-flex;
}

.target-modal-icon-wrapper {
  padding-left: .25rem;
  padding-right: .25rem;
  display: inline;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.target-modal-icon {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 2.5rem;
  height: 2.5rem;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  display: inline;
}

.target-modal-title {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  display: inline;
}

.target-modal-subtitle {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  margin-left: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.target-form-container {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  margin-top: .5rem;
  padding: 1rem;
}

@media (width >= 40rem) {
  .target-form-container {
    margin-top: 1.5rem;
  }
}

.target-input {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  width: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  grid-column: span 2 / span 2;
  display: block;
}

.target-input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
}

@media (width >= 40rem) {
  .target-input {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.target-divider-wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
}

.target-divider-line {
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.target-divider-border {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  width: 100%;
}

.target-divider-text {
  justify-content: center;
  display: flex;
  position: relative;
}

.target-divider-label {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.target-lookup-grid {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: .5rem;
}

@media (width >= 40rem) {
  .target-lookup-grid {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-auto-flow: row dense;
    gap: .5rem;
    display: grid;
  }
}

.target-button-grid {
  margin-top: 1.25rem;
}

@media (width >= 40rem) {
  .target-button-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-flow: row dense;
    gap: .5rem;
    margin-top: 1.5rem;
    display: grid;
  }
}

.target-button-base {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  width: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .375rem;
  justify-content: center;
  margin-top: .75rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.target-button-base:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .target-button-base {
    margin-top: 0;
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.target-close-button {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  width: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .375rem;
  justify-content: center;
  margin-top: .75rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.target-close-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .target-close-button {
    margin-top: 0;
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.target-close-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.target-close-button:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
}

@media (width >= 40rem) {
  .target-close-button {
    grid-column-start: 1;
  }
}

.target-clear-button {
  --tw-border-opacity: 1;
  border-color: rgb(251 146 60 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  width: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .375rem;
  justify-content: center;
  margin-top: .75rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.target-clear-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .target-clear-button {
    margin-top: 0;
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.target-clear-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity, 1));
}

.target-clear-button:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity, 1));
}

@media (width >= 40rem) {
  .target-clear-button {
    grid-column-start: 2;
  }
}

.target-action-button {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-color: #0000;
  border-radius: .375rem;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline;
}

.target-action-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity, 1));
}

.target-action-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .target-action-button {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.time-dropdown-container {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  padding: .5rem .75rem;
  position: relative;
}

.time-dropdown-container:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity, 1));
}

.time-dropdown-label {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  margin-top: -1px;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-block;
  position: absolute;
  top: -.5rem;
  left: .5rem;
}

.time-dropdown-wrapper {
  z-index: 50;
  white-space: nowrap;
  margin-right: .75rem;
  position: relative;
}

.time-dropdown-button {
  cursor: default;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  text-align: left;
  vertical-align: middle;
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  padding: .5rem 3rem .5rem .75rem;
  font-size: .875rem;
  line-height: 1.5rem;
  position: relative;
}

.time-dropdown-button:focus {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity, 1));
  outline: 2px solid #0000;
}

.time-dropdown-icon {
  pointer-events: none;
  align-items: center;
  padding-right: .5rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.time-dropdown-options {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  vertical-align: middle;
  --tw-text-opacity: 1;
  width: 100%;
  max-height: 15rem;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
  --tw-ring-opacity: .05;
  border-radius: .375rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 1rem;
  line-height: 1.75rem;
  position: absolute;
  overflow: auto;
}

.time-dropdown-options:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .time-dropdown-options {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.time-dropdown-option {
  cursor: default;
  user-select: none;
  padding: .5rem 2.25rem .5rem .75rem;
  position: relative;
}

.time-dropdown-option-active {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.time-dropdown-option-inactive {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.time-dropdown-option-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.time-dropdown-check-icon {
  align-items: center;
  padding-right: 1rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.time-dropdown-check-icon-active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.time-dropdown-check-icon-inactive {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.url-input-container {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  display: flex;
  position: relative;
}

.url-input-prefix {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
  align-items: center;
  padding-left: .75rem;
  padding-right: .75rem;
  display: inline-flex;
}

.url-input-prefix:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .url-input-prefix {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.url-input-label {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  border-radius: .25rem;
  margin-top: -1px;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: .5rem;
}

.url-input-label:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.url-input-base {
  --tw-text-opacity: 1;
  min-width: 0;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  background-color: #0000;
  flex: 1;
  display: block;
}

.url-input-base:focus {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
}

.url-input-base:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .url-input-base {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.url-input-with-prefix {
  --tw-border-opacity: 1;
  border-width: 1px 1px 1px 2px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  min-width: 0;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  background-color: #0000;
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
  flex: 1;
  animation: 4s linear infinite alternate both color-change-2x;
  display: block;
}

.url-input-with-prefix:focus {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
}

.url-input-with-prefix:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .url-input-with-prefix {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.url-input-with-prefix:is(.dark *) {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-top-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  border-bottom-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 1;
  border-right-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

.url-input-without-prefix {
  --tw-text-opacity: 1;
  width: 100%;
  min-width: 0;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  background-color: #0000;
  border-radius: .375rem;
  flex: 1;
  display: block;
}

.url-input-without-prefix:focus {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
}

.url-input-without-prefix:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .url-input-without-prefix {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.workflow-dropdown-wrapper {
  z-index: 10;
  white-space: nowrap;
  margin-right: .75rem;
  position: relative;
}

.workflow-dropdown-button {
  cursor: default;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
  text-align: left;
  vertical-align: middle;
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-radius: .25rem;
  padding: .5rem 3rem .5rem .75rem;
  font-size: .875rem;
  line-height: 1.5rem;
  position: relative;
}

.workflow-dropdown-button:focus {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity, 1));
  outline: 2px solid #0000;
}

.workflow-dropdown-button:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.workflow-dropdown-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .875rem;
  line-height: 1.5rem;
  overflow: hidden;
}

.workflow-dropdown-icon-wrapper {
  pointer-events: none;
  align-items: center;
  padding-right: .5rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.workflow-dropdown-options {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  vertical-align: middle;
  --tw-text-opacity: 1;
  width: 100%;
  max-height: 15rem;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
  --tw-ring-opacity: .05;
  border-radius: .375rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 1rem;
  line-height: 1.75rem;
  position: absolute;
  overflow: auto;
}

.workflow-dropdown-options:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.workflow-dropdown-options:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .workflow-dropdown-options {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.workflow-dropdown-option {
  cursor: default;
  user-select: none;
  padding: .5rem 2.25rem .5rem .75rem;
  position: relative;
}

.workflow-dropdown-option-active {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.workflow-dropdown-option-inactive {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.workflow-dropdown-option-inactive:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.workflow-dropdown-check {
  align-items: center;
  padding-right: 1rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.workflow-dropdown-check-active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.workflow-dropdown-check-inactive {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.workflow-input-container {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  border-radius: .25rem;
  padding: .375rem .5rem;
  display: flex;
  position: relative;
}

.workflow-input-container:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity, 1));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity, 1));
}

.workflow-input {
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  background-color: #0000;
  border-width: 0;
  padding: 0;
  display: block;
}

.workflow-input::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity, 1));
}

.workflow-input:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.workflow-input:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .workflow-input {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.adapters-layout {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.adapters-main {
  flex: 1;
  padding: 1rem;
  overflow: hidden auto;
}

.adapters-card {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
}

.adapters-card:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.adapters-content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.adapters-content {
  padding: 1rem;
}

.adapters-name-wrapper {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.adapters-name-container {
  flex: 1;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.adapters-icon-container {
  flex-shrink: 0;
}

.adapters-icon-button {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  padding: .5rem .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
}

.adapters-icon-button:hover {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
}

.adapters-name-input-wrapper {
  flex: 1;
  min-width: 0;
}

.adapters-status-button {
  border-radius: .375rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
}

.adapters-status-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.adapters-status-active {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}

.adapters-status-active:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity, 1));
}

.adapters-status-active:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity, 1));
}

.adapters-status-inactive {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity, 1));
}

.adapters-status-inactive:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity, 1));
}

.adapters-status-inactive:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity, 1));
}

.adapters-description > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.adapters-description-text {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  line-height: 1.625;
}

.adapters-description-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.adapters-selection {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
  border-radius: .5rem;
  padding: 1.5rem;
}

.adapters-selection-content {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  display: flex;
}

.adapters-dropdown-container {
  width: 100%;
  max-width: 24rem;
}

.adapters-dropdown-title {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  margin-bottom: .5rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.adapters-dropdown-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.adapters-arrow {
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
  flex-shrink: 0;
  margin-top: 2rem;
}

.adapters-arrow:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.adapters-sample > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.adapters-sample-header {
  align-items: center;
  display: flex;
}

.adapters-sample-container {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  border-radius: .5rem;
  padding: 1rem;
  transition-property: box-shadow;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
}

.adapters-sample-container:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity, 1));
}

.adapters-sample-container:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.adapters-sample-content {
  position: relative;
}

.adapters-sample-textarea {
  resize: vertical;
  --tw-text-opacity: 1;
  width: 100%;
  min-height: 200px;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  background-color: #0000;
  border-width: 0;
  font-size: .875rem;
  line-height: 1.5rem;
}

.adapters-sample-textarea::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity, 1));
}

.adapters-sample-textarea:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.adapters-sample-textarea:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.adapters-sample-textarea:is(.dark *)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity, 1));
}

.elayer-container {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.elayer-content {
  flex: 1;
}

.elayer-content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.elayer-content {
  padding: 1rem;
  overflow-y: auto;
}

.elayer-message-section > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.elayer-message-heading {
  display: flex;
}

.elayer-message-container {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  border-radius: .5rem;
  padding: .5rem 0;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
}

.elayer-message-container:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.elayer-message-container:hover:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(8 145 178 / var(--tw-border-opacity, 1));
}

.elayer-message-content {
  justify-content: space-between;
  gap: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
  display: flex;
}

.elayer-message-text {
  min-width: 0;
  display: flex;
}

.elayer-message-info {
  word-break: break-all;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.5rem;
}

.elayer-message-warning {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity, 1));
  margin-left: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.elayer-message-warning:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}

.elayer-message-note {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity, 1));
  margin-left: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.elayer-message-note:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.elayer-copy-button {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  align-items: center;
  gap: .5rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
  display: flex;
}

.elayer-copy-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity, 1));
}

.elayer-copy-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.elayer-copy-button:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity, 1));
}

.elayer-copy-button:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity, 1));
}

.elayer-copy-icon {
  --tw-text-opacity: 1;
  width: 1rem;
  height: 1rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.elayer-webhook-container > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.elayer-webhook-label {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: block;
}

.elayer-webhook-label:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.elayer-filter-container {
  margin-top: .5rem;
}

.elayer-filter-content {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
}

.elayer-filter-content:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.filter-table {
  table-layout: auto;
  border-collapse: separate;
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  width: 100%;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.table-cell-filter {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  background: inherit;
  width: 20%;
  padding: 1rem 1.5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.filter-header {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.filter-header:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.filter-title-section {
  align-items: center;
  display: flex;
}

.view-toggle-container {
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  align-items: center;
  gap: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
  display: flex;
}

.table-header-cell-first {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  padding: .75rem 1.5rem;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.table-header-cell-first:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.table-header-cell-first {
  background: inherit;
  width: 7%;
}

.table-header-cell {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  padding: .75rem 1.5rem;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.table-header-cell:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.table-header-cell {
  background: inherit;
}

.table-header-cell:nth-child(2) {
  width: 23%;
}

.table-header-cell:nth-child(3) {
  width: 50%;
}

.table-header-cell:nth-child(4) {
  width: 20%;
}

.filter-input-container {
  align-items: center;
  gap: .5rem;
  width: 100%;
  display: flex;
}

.filter-header-row {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 4rem;
  padding: 1rem;
  display: flex;
}

.filter-header-content {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.filter-title-section {
  flex: 1;
  align-items: center;
  display: flex;
}

.filter-title {
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.filter-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.filter-controls {
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
  display: flex;
}

.sftp-config-button {
  cursor: pointer;
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  align-items: center;
  gap: .5rem;
  padding: .5rem .75rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
  display: inline-flex;
}

.view-toggle-container {
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  align-items: center;
  gap: .5rem;
  margin-left: auto;
  font-size: .875rem;
  line-height: 1.5rem;
  display: flex;
}

.filter-divider {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
  height: 1px;
  margin-top: .5rem;
}

.filter-divider:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.filter-input {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  border-radius: .375rem;
  flex: 1;
  padding: .5rem .75rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.filter-input:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.filter-delete {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  flex-shrink: 0;
  padding: .375rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
}

.filter-delete:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.filter-delete:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.filter-delete-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.sftp-config-button {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  align-items: center;
  gap: .5rem;
  padding: .5rem .75rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
  display: flex;
}

.sftp-config-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.sftp-config-button:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.sftp-config-button:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.sftp-config-icon {
  width: 1rem;
  height: 1rem;
}

.sftp-config-text {
  font-size: .875rem;
  line-height: 1.5rem;
}

.view-toggle-container {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  align-items: center;
  gap: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
  display: flex;
}

.view-toggle-container:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.table-header-icon {
  cursor: pointer;
  --tw-text-opacity: 1;
  width: 1rem;
  height: 1rem;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.table-header-icon:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.table-header-icon:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.table-row {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.table-row:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.table-row-even:hover {
  background-color: #06b6d41a;
}

.table-row-odd {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.table-row-odd:hover {
  background-color: #06b6d41a;
}

.table-row-success {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(74 222 128 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.table-row-success:hover {
  background-color: #06b6d41a;
}

.table-row-success:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.table-row-error {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(244 114 182 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.table-row-error:hover {
  background-color: #ec48991a;
}

.table-row-error:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.table-cell {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.table-cell-first {
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem clamp(4rem, 2.57rem + 3.57vw, 6rem) .5rem 1rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.table-cell-first:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.table-cell-spec {
  white-space: normal;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem;
  font-size: .8125rem;
  font-weight: 300;
  line-height: 1.5rem;
}

.table-cell-sample {
  white-space: normal;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.table-cell-filter {
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
  display: flex;
}

.table-cell-filter:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.filter-input {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  width: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  display: flex;
}

.filter-input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity, 1));
}

.filter-input:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

@media (width >= 40rem) {
  .filter-input {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.filter-delete {
  border-radius: .375rem;
  padding-top: .25rem;
  padding-left: .5rem;
  display: inline;
}

.filter-delete-icon {
  cursor: pointer;
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  display: inline;
}

.filter-delete-icon:hover {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}

.delete-button {
  border-radius: .375rem;
  padding-top: .25rem;
  padding-left: .5rem;
  display: inline;
}

.delete-icon {
  cursor: pointer;
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  display: inline;
}

.message-id-row-match {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(74 222 128 / var(--tw-border-opacity, 1));
}

.message-id-row-match:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.message-id-row-match:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.message-id-row-mismatch {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(244 114 182 / var(--tw-border-opacity, 1));
}

.message-id-row-mismatch:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}

.message-id-row-mismatch:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.elayer-container {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: scroll;
}

.elayer-content {
  flex-grow: 1;
  display: inline;
  overflow-y: scroll;
}

.elayer-message-section {
  margin-left: .25rem;
  margin-right: .25rem;
}

.elayer-message-heading {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity, 1));
}

.elayer-message-heading:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.elayer-message-container {
  border-radius: .25rem;
  width: 100%;
  padding: 1rem;
  position: relative;
}

.elayer-message-content {
  display: flex;
}

.elayer-message-text {
  text-align: center;
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  flex: 1;
  font-size: 1.25rem;
  line-height: 2rem;
}

.elayer-message-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity, 1));
}

.elayer-message-info {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.elayer-message-info:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.elayer-message-warning {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.5rem;
}

.elayer-message-note {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}

.elayer-copy-button {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.elayer-copy-button:hover {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
}

.elayer-copy-button:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.elayer-copy-icon {
  --tw-text-opacity: 1;
  width: 1rem;
  height: 1rem;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-top: 0;
  padding-top: 0;
  display: inline;
}

.elayer-copy-icon:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.elayer-webhook-container {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  padding: .5rem;
  display: flex;
  position: relative;
}

.elayer-webhook-label {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  margin-top: -1px;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: .5rem;
}

.elayer-webhook-label:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .elayer-filter-container {
    border-radius: .375rem;
    overflow: hidden;
  }
}

.elayer-filter-content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.elayer-filter-content {
  border-radius: .125rem;
  padding-bottom: 1.5rem;
}

.elayer-filter-table {
  width: 100%;
}

.elayer-filter-table > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-divide-opacity, 1));
}

.elayer-filter-table {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.elayer-table-header:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.elayer-table-title-row {
  justify-content: space-between;
  align-items: center;
  padding-top: .5rem;
  padding-left: .75rem;
  padding-right: .75rem;
  display: flex;
}

.elayer-table-title {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
}

.elayer-table-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.elayer-table-controls {
  align-items: center;
  display: flex;
}

.elayer-table-controls > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.elayer-sftp-button {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
  border-radius: .375rem;
  align-items: center;
  padding-left: .5rem;
  padding-right: .5rem;
  display: flex;
}

.elayer-sftp-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.elayer-sftp-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.elayer-sftp-icon:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.elayer-sftp-text {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.elayer-sftp-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.elayer-toggle-container {
  align-items: center;
  display: flex;
}

.elayer-toggle-container > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.elayer-toggle-container {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.elayer-toggle-container:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.elayer-table-divider {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-top-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
}

.elayer-th-base {
  text-align: left;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
  padding: .5rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.elayer-th-field {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
  padding: .5rem .5rem .5rem 1rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.elayer-info-icon {
  cursor: pointer;
  vertical-align: middle;
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(249 168 212 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding-bottom: .25rem;
  display: inline;
}

.elayer-info-icon:hover {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
}

.elayer-tr-even {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.elayer-tr-even:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.elayer-tr-even:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.elayer-tr-odd:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.elayer-tr-odd:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.elayer-tr-success {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(74 222 128 / var(--tw-border-opacity, 1));
}

.elayer-tr-success:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.elayer-tr-success:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.elayer-tr-error {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(244 114 182 / var(--tw-border-opacity, 1));
}

.elayer-tr-error:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}

.elayer-tr-error:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.elayer-td-field {
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem .5rem .5rem 1rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.elayer-td-field:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.elayer-td-spec {
  white-space: normal;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem;
  font-size: .8125rem;
  font-weight: 300;
  line-height: 1.5rem;
}

.elayer-td-spec:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.elayer-td-sample {
  white-space: normal;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.elayer-td-sample:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.elayer-td-filter {
  white-space: normal;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
  display: flex;
}

.elayer-td-filter:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.elayer-filter-input {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  width: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  display: flex;
}

.elayer-filter-input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity, 1));
}

.elayer-filter-input:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

@media (width >= 40rem) {
  .elayer-filter-input {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.elayer-delete-button {
  border-radius: .375rem;
  padding-top: .25rem;
  padding-left: .5rem;
  display: inline;
}

.elayer-delete-icon {
  cursor: pointer;
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  display: inline;
}

.elayer-delete-icon:hover {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}

.tlayer-container {
  width: 100%;
  overflow-y: scroll;
}

.tlayer-content {
  flex: 1;
}

.tlayer-content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.tlayer-content {
  padding: 1rem;
  overflow-y: auto;
}

@media (width >= 40rem) {
  .tlayer-content {
    border-radius: .375rem;
    overflow: hidden;
  }
}

.tlayer-section > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.tlayer-section {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  border-radius: .375rem;
}

.tlayer-section:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.data-map-table {
  min-width: 100%;
}

.data-map-table > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-divide-opacity, 1));
}

.data-map-table {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-map-table:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.data-map-header {
  background-color: #0000;
}

.data-map-header:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.data-map-title-row {
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
}

.data-map-title {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-top: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
}

.data-map-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.data-map-controls {
  align-items: center;
  display: flex;
}

.data-map-controls > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.fhir-button {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  align-items: center;
  margin-left: .25rem;
  margin-right: .5rem;
  display: inline-flex;
}

.fhir-button:hover {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.fhir-button-loading {
  position: relative;
}

@keyframes wiggle {
  0%, 100% {
    transform: rotate(-2deg);
  }

  50% {
    transform: rotate(2deg);
  }
}

.fhir-button-loading {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(236 72 153 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  padding: .25rem .5rem;
  animation: 1s ease-in-out infinite wiggle;
}

.fhir-button-loading:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.fhir-progress-bar {
  background-color: #ec4899cc;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.fhir-button-idle {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-radius: .375rem;
  padding: .25rem .5rem;
}

.fhir-button-idle:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.fhir-button-idle:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.fhir-icon {
  cursor: pointer;
  --tw-text-opacity: 1;
  width: 2rem;
  height: 1.5rem;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  display: inline;
}

.fhir-icon:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.toggle-container {
  align-items: center;
  display: flex;
}

.toggle-container > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.toggle-container {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.5rem;
}

.toggle-container:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.table-divider {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-top-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
}

.table-header {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
  padding: .5rem 1rem;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.table-header-sample {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  padding: .5rem;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.table-row-even {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.table-row-even:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.table-row-even:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.table-row-odd:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.table-row-odd:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.table-cell {
  white-space: nowrap;
  padding-top: .25rem;
  padding-left: 1rem;
  padding-right: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.table-cell:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.table-cell-spec {
  text-overflow: ellipsis;
  white-space: nowrap;
  white-space: normal;
  padding: .25rem .5rem .5rem;
  font-size: .8125rem;
  font-weight: 300;
  line-height: 1.5rem;
  overflow: hidden;
}

.table-cell-spec:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.table-cell-sample {
  white-space: normal;
  padding: .25rem .5rem .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.table-cell-sample:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.table-cell-actions {
  white-space: normal;
  width: 100%;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
  display: flex;
}

.table-cell-actions:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.table-input {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  border-radius: .25rem;
  width: 100%;
  display: flex;
}

.table-input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity, 1));
}

.table-input:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

@media (width >= 40rem) {
  .table-input {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.action-button {
  border-radius: .375rem;
  margin-top: .25rem;
  padding-left: .5rem;
  display: inline;
}

.cog-icon {
  cursor: pointer;
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  display: inline;
}

.cog-icon:hover {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.delete-button {
  border-radius: .375rem;
  margin-left: .25rem;
  padding-top: .25rem;
  padding-left: .25rem;
  display: inline;
}

.delete-icon {
  cursor: pointer;
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  display: inline;
}

.delete-icon:hover {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}

.loading-container {
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (width >= 48rem) {
  .loading-container {
    grid-column: span 7 / span 7;
    margin-top: 0;
  }
}

.loading-content {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.loading-content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.loading-content {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  padding: 1.5rem;
}

.loading-header {
  width: 100%;
  display: inline-flex;
}

.loading-toggle {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  align-items: center;
  margin-left: .25rem;
  margin-right: .5rem;
  padding: .25rem .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
  display: inline-flex;
}

.loading-toggle:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.loading-animation {
  align-items: center;
  padding-top: 12rem;
  padding-bottom: 12rem;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@media (width >= 40rem) {
  .loading-animation {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

@media (width >= 48rem) {
  .loading-animation {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media (width >= 64rem) {
  .loading-animation {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

.loading-logo {
  width: auto;
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.loading-title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.loading-version {
  vertical-align: middle;
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
  padding-left: .25rem;
  font-size: .8125rem;
  line-height: 1.5rem;
  display: inline;
}

.llayer-container {
  width: 100%;
  overflow-y: scroll;
}

@media (width >= 40rem) {
  .llayer-content {
    border-radius: .125rem;
  }
}

.llayer-section > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.llayer-section {
  border-radius: .125rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

.llayer-main {
  width: 100%;
  margin-bottom: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.destination-container {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  margin-left: .5rem;
  margin-right: .5rem;
  padding: 1rem;
}

.destination-mllp {
  z-index: auto;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  gap: 1rem;
  display: grid;
}

.destination-tunnel {
  grid-column: span 2 / span 2;
}

.destination-ip {
  grid-column: span 4 / span 4;
  margin-top: .5rem;
}

.destination-port {
  grid-column: span 1 / span 1;
  margin-top: .5rem;
}

.destination-s3 {
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.destination-s3-inputs {
  flex-grow: 1;
  width: 100%;
  display: flex;
}

.destination-s3-inputs > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.pipedream-container {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .125rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .5rem;
  position: relative;
}

.pipedream-label {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .125rem;
  margin-top: -1px;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: .5rem;
}

.pipedream-label:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.pipedream-input-wrapper {
  margin-top: .75rem;
  display: flex;
}

.pipedream-input-container {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  flex: 1;
  font-size: 1.25rem;
  line-height: 2rem;
  display: inline;
}

.pipedream-input {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  background-color: #0000;
  border-radius: .375rem;
  width: 100%;
  display: block;
}

.pipedream-input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
}

.pipedream-input:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

@media (width >= 40rem) {
  .pipedream-input {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.http-warning {
  padding-bottom: .5rem;
}

.http-warning-text {
  --tw-border-opacity: 1;
  border-width: 4px;
  border-color: rgb(254 215 170 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  margin: 1rem .25rem;
  padding: 1.5rem 1rem;
  font-size: 1.25rem;
  line-height: 2rem;
}

.http-warning-icon {
  --tw-text-opacity: 1;
  width: 3rem;
  height: 3rem;
  color: rgb(253 186 116 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  display: inline;
}

.auth-header-container {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .125rem;
  grid-column: span 2 / span 2;
  margin-top: 1rem;
  padding-left: .75rem;
  padding-right: .75rem;
  position: relative;
}

.auth-header-label {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  border-radius: .125rem;
  margin-top: -1px;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: .5rem;
}

.auth-header-label:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.auth-header-content {
  margin-top: .25rem;
  display: flex;
  overflow: auto;
}

.auth-header-text {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.auth-header-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.preview-container {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  grid-column: span 2 / span 2;
  margin-left: .5rem;
  margin-right: .5rem;
  padding: .5rem 1rem;
  position: relative;
}

.preview-container:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.preview-container-small {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  border-radius: .25rem;
  grid-column: span 2 / span 2;
  margin-top: 1.5rem;
  padding: .5rem;
  position: relative;
}

.preview-label {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  margin-top: -1px;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: .5rem;
}

.preview-label:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.preview-content {
  margin-top: .25rem;
  display: flex;
}

.preview-text {
  text-wrap: wrap;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.preview-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity, 1));
}

.fhir-container {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  width: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .125rem;
  grid-column: span 2 / span 2;
  margin-top: 1rem;
  padding-left: .75rem;
  padding-right: .75rem;
  position: relative;
}

.fhir-label {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  border-radius: .125rem;
  margin-top: -1px;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: .5rem;
}

.fhir-label:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.fhir-content {
  margin-top: .25rem;
  display: flex;
}

.fhir-text {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.fhir-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.workflow-card {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  height: 8rem;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding: .75rem .75rem 1rem;
  position: relative;
  overflow: hidden;
}

.workflow-card:hover {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
}

.workflow-card:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .workflow-card {
    padding-top: .75rem;
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

.workflow-card-icon-wrapper {
  padding-top: .125rem;
  position: absolute;
}

.workflow-card-icon {
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
}

.workflow-card-title-container {
  cursor: pointer;
  margin-right: 1.25rem;
  padding-left: 1.75rem;
}

.workflow-card-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  padding-right: .5rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  overflow: hidden;
}

.workflow-card-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.workflow-card-delete-btn {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  margin: .5rem;
  padding: .125rem .5rem;
  font-size: .8125rem;
  font-weight: 700;
  line-height: 1.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.workflow-card-delete-btn:hover {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.workflow-card-delete-btn:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.workflow-card-trigger {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  margin-left: 0;
  padding-left: 0;
  padding-right: .25rem;
  font-size: .875rem;
  line-height: 1.5rem;
  overflow: hidden;
}

.workflow-card-trigger:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.workflow-card-stats-container {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  text-align: center;
  border-radius: .25rem;
  align-items: baseline;
  width: fit-content;
  padding-left: .5rem;
  padding-right: .5rem;
  display: flex;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

.workflow-card-success-count {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
}

.workflow-card-success-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(74 222 128 / var(--tw-text-opacity, 1));
  flex-shrink: 0;
  align-self: center;
}

.workflow-card-failure-count {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity, 1));
  margin-left: 0;
  padding-left: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
}

.workflow-card-failure-count-number {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
}

.workflow-card-failure-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
  flex-shrink: 0;
  align-self: center;
}

.workflows-grid {
  gap: .5rem;
  width: 100%;
  padding-top: .5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  animation: 4s linear infinite alternate both color-change-2x;
  display: grid;
}

.workflows-grid:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 40rem) {
  .workflows-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 48rem) {
  .workflows-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (width >= 64rem) {
  .workflows-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (width >= 80rem) {
  .workflows-grid {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (width >= 96rem) {
  .workflows-grid {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.workflows-empty-state {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
  grid-column: span 6 / span 6;
  padding-top: clamp(6rem, 1.71rem + 10.71vw, 12rem);
  font-size: 1.5rem;
  line-height: 2rem;
}

.workflows-empty-state-text {
  padding-left: clamp(8rem, 3rem + 12.5vw, 15rem);
  padding-right: clamp(8rem, 3rem + 12.5vw, 15rem);
}

.workflow-events-sidebar {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  flex-shrink: 0;
  height: 100%;
}

.workflow-events-sidebar-content {
  border-right-width: 2px;
  border-top-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
  border-bottom-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 1;
  border-right-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  min-width: 10rem;
  max-width: 12rem;
  height: 100%;
}

.workflow-events-sidebar-content:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.workflow-events-list {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  border-radius: .25rem;
  width: 100%;
  overflow-y: auto;
}

.workflow-events-list:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}

@media (width >= 40rem) {
  .workflow-events-list {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.workflow-events-empty-state {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding-top: 6rem;
  padding-bottom: 6rem;
  font-size: 1.25rem;
  line-height: 2rem;
}

.workflow-events-empty-state:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.workflow-event-item {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
}

.workflow-event-separator {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: .125rem;
  height: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  margin-left: -1px;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.workflow-event-separator:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.workflow-event-icon-container {
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.workflow-event-icon-container:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity, 1));
}

.workflow-event-details {
  flex: 1;
  justify-content: space-between;
  min-width: 0;
  display: flex;
}

.workflow-event-details > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.workflow-event-details {
  padding-top: .375rem;
}

.workflow-event-message {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding-left: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.workflow-event-message:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.workflow-event-timestamp {
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.5rem;
}

.workflow-event-timestamp:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.workflows-loading {
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 12rem;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@media (width >= 40rem) {
  .workflows-loading {
    padding: 6rem;
  }
}

@media (width >= 48rem) {
  .workflows-loading {
    padding: 8rem;
  }
}

@media (width >= 64rem) {
  .workflows-loading {
    padding: 12rem;
  }
}

.workflows-loading-logo {
  width: auto;
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.workflows-loading-title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.workflows-loading-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.workflows-loading-version {
  vertical-align: middle;
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
  padding-left: .25rem;
  font-size: .8125rem;
  line-height: 1.5rem;
  display: inline;
}

.workflows-main-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.workflows-sidebar {
  border-right-width: 2px;
  border-top-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
  border-bottom-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 1;
  border-right-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
  background-color: #374151b3;
  min-width: 9rem;
  max-width: 11rem;
  height: 100%;
}

.workflows-sidebar:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.workflows-sidebar-content {
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.account-dropdown-container {
  position: relative;
}

.account-dropdown-button {
  cursor: default;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(8 145 178 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  text-align: left;
  vertical-align: middle;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  width: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  padding: .5rem 1.25rem .5rem .75rem;
  position: relative;
}

.account-dropdown-button:focus {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .account-dropdown-button {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.account-dropdown-button-text {
  vertical-align: middle;
  display: block;
}

.account-dropdown-button-icon-wrapper {
  pointer-events: none;
  align-items: center;
  padding-right: .5rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.account-dropdown-options {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  vertical-align: middle;
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  width: 100%;
  max-height: 15rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
  --tw-ring-opacity: .05;
  border-radius: .375rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 1rem;
  line-height: 1.75rem;
  overflow: auto;
}

@media (width >= 40rem) {
  .account-dropdown-options {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.account-dropdown-check-icon-wrapper {
  align-items: center;
  padding-right: 1rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.permissions-modal-container {
  z-index: 10;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.permissions-modal-wrapper {
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  min-height: 100vh;
  padding: 1rem 1rem 5rem;
  display: flex;
}

@media (width >= 40rem) {
  .permissions-modal-wrapper {
    padding: 0;
    display: block;
  }
}

.permissions-modal-overlay {
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .75;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  inset: 0;
}

.permissions-modal-content {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  text-align: left;
  vertical-align: bottom;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: .5rem .5rem 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
  position: relative;
  overflow: hidden;
}

@media (width >= 40rem) {
  .permissions-modal-content {
    vertical-align: middle;
    width: 100%;
    max-width: 32rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.permissions-modal-header {
  padding-bottom: 1.5rem;
  padding-left: .25rem;
  padding-right: .25rem;
  display: inline-flex;
}

.permissions-modal-header-icon-wrapper {
  padding-left: .25rem;
  padding-right: .25rem;
  display: inline;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.permissions-modal-header-icon {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 2.5rem;
  height: 2.5rem;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  display: inline;
}

.permissions-modal-header-title {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  display: inline;
}

.permissions-modal-body {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  margin-top: .5rem;
  padding: .25rem;
}

@media (width >= 40rem) {
  .permissions-modal-body {
    margin-top: 1.5rem;
  }
}

.permissions-modal-grid {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: .25rem;
}

@media (width >= 40rem) {
  .permissions-modal-grid {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: .25rem;
    display: grid;
  }
}

.permissions-modal-label {
  grid-column: span 3 / span 3;
}

.permissions-modal-toggle-wrapper {
  grid-column: span 9 / span 9;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.permissions-modal-workflows-toggle {
  grid-column: span 2 / span 2;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.permissions-modal-footer {
  margin-top: 1.25rem;
}

@media (width >= 40rem) {
  .permissions-modal-footer {
    margin-top: 1.5rem;
  }
}

.permissions-modal-close-button {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  justify-content: center;
  margin-top: .75rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.permissions-modal-close-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.permissions-modal-close-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .permissions-modal-close-button {
    grid-column-start: 1;
    margin-top: 0;
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.workspace-loading-container {
  align-items: center;
  padding-top: 12rem;
  padding-bottom: 12rem;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@media (width >= 40rem) {
  .workspace-loading-container {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

@media (width >= 48rem) {
  .workspace-loading-container {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media (width >= 64rem) {
  .workspace-loading-container {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

.workspace-loading-logo {
  width: auto;
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.workspace-loading-text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.workspace-sidebar {
  background-color: #374151b3;
  flex-shrink: 0;
  height: 100%;
}

.workspace-sidebar-inner {
  border-right-width: 2px;
  border-top-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
  border-bottom-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 1;
  border-right-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
  background-color: #374151b3;
  min-width: 8rem;
  max-width: 9rem;
  height: 100%;
}

.workspace-sidebar-inner:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.workspace-sidebar-nav {
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.workspace-main-container {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
  width: 100%;
  height: 100%;
}

.workspace-main-container:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.workspace-main-content {
  display: inline;
  overflow-y: scroll;
}

.workspace-main-content:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.workspace-settings-container {
  margin-bottom: 1.5rem;
  padding: .5rem;
}

@media (width >= 40rem) {
  .workspace-settings-container {
    vertical-align: middle;
  }
}

.workspace-settings-inputs {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  gap: .5rem;
  margin-top: .75rem;
}

.workspace-settings-inputs:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.workspace-settings-security-warning {
  --tw-border-opacity: 1;
  border-width: 4px;
  border-color: rgb(252 165 165 / var(--tw-border-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.workspace-settings-save-button {
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  align-items: center;
  padding: .25rem 8rem;
  font-size: 1.25rem;
  line-height: 2rem;
}

.workspace-settings-save-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity, 1));
}

.workspace-settings-save-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity, 1));
  outline: 2px solid #0000;
}

.workspace-permissions-container {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(192 132 252 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
  height: 100%;
  margin-bottom: .5rem;
  overflow-y: auto;
}

.workspace-permissions-container:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.workspace-permissions-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: .5rem;
  height: 100%;
  margin-left: .5rem;
  margin-right: .5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: grid;
}

.workspace-permissions-empty {
  --tw-text-opacity: 1;
  min-height: 100%;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  grid-column: span 4 / span 4;
  padding-top: clamp(12rem, 7.71rem + 10.71vw, 18rem);
  padding-bottom: clamp(12rem, 7.71rem + 10.71vw, 18rem);
  display: flex;
}

.workspace-permissions-user-card {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(192 132 252 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
  display: flex;
}

.workspace-permissions-user-card:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.workspace-permissions-user-card:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.workspace-permissions-user-remove-button {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .125rem;
  padding: .25rem .75rem;
  font-size: .625rem;
  line-height: 1rem;
}

.workspace-permissions-user-remove-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}

.workspace-permissions-user-remove-button:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.workspace-permissions-user-remove-button:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.workspace-invitations-container {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.workspace-invitations-list {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(192 132 252 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
  height: 100%;
  margin-bottom: .5rem;
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.workspace-invitations-list:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.workspace-invitations-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: .5rem;
  height: 100%;
  margin-left: .5rem;
  margin-right: .5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: grid;
}

.workspace-invitations-empty {
  --tw-text-opacity: 1;
  height: 100%;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  grid-column: span 4 / span 4;
}

.workspace-invitations-invite-card {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(244 114 182 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
  display: flex;
}

.workspace-invitations-invite-card:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.workspace-invitations-invite-card:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.workspace-invitations-send-button {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(192 132 252 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding: .5rem .25rem;
}

.workspace-invitations-send-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity, 1));
}

.workspace-invitations-sending-button {
  width: 100%;
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.workspace-invitations-sending-button {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding: .5rem .25rem;
  animation: 1s infinite bounce;
}

.workspace-invitations-sending-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}

.workspace-invitations-sending-button:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.permissions-section-container {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(192 132 252 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
  height: 100%;
  margin-bottom: .5rem;
  overflow-y: auto;
}

.permissions-section-container:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.permissions-section-inner {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
  overflow-y: auto;
}

.permissions-section-inner:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.permissions-section-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: .5rem;
  height: 100%;
  margin-left: .5rem;
  margin-right: .5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: grid;
}

.permissions-empty-state {
  --tw-text-opacity: 1;
  min-height: 100%;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  grid-column: span 4 / span 4;
  padding-top: clamp(12rem, 7.71rem + 10.71vw, 18rem);
  padding-bottom: clamp(12rem, 7.71rem + 10.71vw, 18rem);
  display: flex;
}

.permissions-empty-state-content {
  text-align: center;
  vertical-align: middle;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

.permissions-empty-state-icon {
  vertical-align: middle;
  --tw-text-opacity: 1;
  width: 2.25rem;
  height: 2.25rem;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  margin-right: .25rem;
  display: inline;
}

.permissions-member-card {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(192 132 252 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
  display: flex;
}

.permissions-member-card:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.permissions-member-card:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.permissions-member-content {
  align-items: center;
  font-size: .8125rem;
  line-height: 1.5rem;
  display: flex;
}

.permissions-member-image {
  border-radius: .25rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .5rem;
  display: inline;
}

.permissions-member-remove-button {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .125rem;
  padding: .25rem .75rem;
  font-size: .625rem;
  line-height: 1rem;
}

.permissions-member-remove-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}

.permissions-member-remove-button:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.permissions-member-remove-button:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.settings-section-container {
  margin-bottom: 1.5rem;
  padding: .5rem;
}

@media (width >= 40rem) {
  .settings-section-container {
    align-items: center;
  }
}

.settings-section-input-wrapper {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  gap: .5rem;
  margin-top: .75rem;
}

.settings-section-input-wrapper:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.settings-section-input-text {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.settings-section-input-text:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.settings-section-grid {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: .5rem;
  margin-top: 1.5rem;
  display: grid;
}

.settings-section-security-segment {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  grid-column: span 4 / span 4;
}

.settings-section-security-segment:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.settings-section-security-token {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  grid-column: span 8 / span 8;
}

.settings-section-security-token:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.settings-section-alert {
  --tw-border-opacity: 1;
  border-width: 4px;
  border-color: rgb(252 165 165 / var(--tw-border-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.settings-section-alert-icon {
  --tw-text-opacity: 1;
  width: 1.5rem;
  height: 1.5rem;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  display: inline;
}

.settings-section-alert-text {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
  padding-left: .75rem;
}

.settings-section-save-button {
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  align-items: center;
  padding: .25rem 8rem;
  font-size: 1.25rem;
  line-height: 2rem;
}

.settings-section-save-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity, 1));
}

.settings-section-save-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity, 1));
  outline: 2px solid #0000;
}

.settings-section-save-icon {
  width: 2rem;
  height: 2rem;
  margin-right: .5rem;
  display: inline;
}

.account-dropdown-container {
  width: 100%;
  position: relative;
}

.account-dropdown-button {
  cursor: default;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  text-align: left;
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  width: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  padding: .5rem 2.5rem .5rem .75rem;
  position: relative;
}

.account-dropdown-button:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.account-dropdown-button:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity, 1));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
  --tw-ring-opacity: .75;
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fdba74;
}

@media (width >= 40rem) {
  .account-dropdown-button {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.account-dropdown-button-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
  display: block;
  overflow: hidden;
}

.account-dropdown-button-icon-wrapper {
  align-items: center;
  padding-right: .5rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.account-dropdown-button-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.account-dropdown-options {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  width: 100%;
  max-height: 15rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
  --tw-ring-opacity: .05;
  border-radius: .375rem;
  margin-top: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 1rem;
  line-height: 1.75rem;
  position: absolute;
  overflow: auto;
}

.account-dropdown-options:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .account-dropdown-options {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.account-dropdown-option {
  cursor: default;
  user-select: none;
  padding: .5rem 1rem .5rem 2.5rem;
  position: relative;
}

.account-dropdown-option-active {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity, 1));
}

.account-dropdown-option-inactive {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.account-dropdown-option-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.account-dropdown-option-text-selected {
  font-weight: 500;
}

.account-dropdown-option-text-unselected {
  font-weight: 400;
}

.account-dropdown-check-icon-wrapper {
  align-items: center;
  padding-left: .75rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.account-dropdown-check-icon-active {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity, 1));
}

.account-dropdown-check-icon-inactive {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.payment-modal-overlay {
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .75;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  inset: 0;
}

.payment-modal-container {
  z-index: 10;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.payment-modal-wrapper {
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  min-height: 100%;
  padding: 1rem;
  display: flex;
}

@media (width >= 40rem) {
  .payment-modal-wrapper {
    align-items: center;
    padding: 0;
  }
}

.payment-modal-panel {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
  text-align: left;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: 1.25rem 1rem 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
  overflow: hidden;
}

@media (width >= 40rem) {
  .payment-modal-panel {
    width: 100%;
    max-width: 24rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
  }
}

.payment-modal-logo {
  width: auto;
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.payment-modal-title {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.payment-modal-product-name {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  padding-bottom: .5rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.payment-modal-product-price {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity, 1));
  padding-bottom: .5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.payment-modal-payment-title {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  padding-bottom: .5rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.payment-modal-submit-button {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 0px;
  border-width: 1px;
  border-color: #0000;
  border-radius: .375rem;
  justify-content: center;
  margin-top: 1rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.payment-modal-submit-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity, 1));
}

.payment-modal-submit-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .payment-modal-submit-button {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.payment-modal-back-button {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 0px;
  border-width: 1px;
  border-color: #0000;
  border-radius: .375rem;
  justify-content: center;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.payment-modal-back-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.payment-modal-back-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .payment-modal-back-button {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.env-card {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  border-radius: .5rem;
  padding: 1.5rem 1rem .5rem;
  position: relative;
  overflow: hidden;
}

.env-card:hover {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
}

.env-card:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.env-loading-container {
  align-items: center;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.env-loading-logo {
  width: auto;
  height: 3.5rem;
  margin-left: auto;
  margin-right: auto;
}

.env-loading-text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  margin-top: 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
}

.env-header {
  display: flex;
}

.env-save-button {
  border-radius: .375rem;
  margin-left: .25rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

.env-save-icon {
  --tw-text-opacity: 1;
  width: 1.75rem;
  height: 1.25rem;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.env-save-icon:hover {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.env-delete-button {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  padding: .25rem .5rem;
  font-size: .8125rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.env-delete-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.env-delete-button:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.env-info {
  place-items: center;
  padding-top: .5rem;
}

.env-info-text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.env-stack-grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: .5rem;
  padding-top: 1rem;
  display: grid;
}

.env-stack-container {
  text-align: center;
}

.env-stack-name {
  text-transform: capitalize;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.env-stack-status {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  font-size: .8125rem;
  line-height: 1.5rem;
  overflow: hidden;
}

.env-stack-status-notfound {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  font-size: .8125rem;
  line-height: 1.5rem;
}

.env-stack-actions {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  align-items: center;
  margin-top: .25rem;
  font-weight: 700;
  display: flex;
}

.env-stack-action-container {
  align-items: center;
  width: 100%;
  margin-left: .5rem;
  margin-right: .5rem;
}

.env-stack-action-container > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.env-stack-delete-button {
  background-color: #0000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.env-stack-delete-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}

.env-stack-delete-button:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.env-stack-delete-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}

.env-stack-deploy-button {
  background-color: #0000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.env-stack-deploy-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.env-stack-deploy-button:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.env-stack-deploy-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.env-empty-container {
  justify-content: center;
  align-items: center;
  padding-top: clamp(8rem, .86rem + 17.86vw, 18rem);
  padding-bottom: clamp(8rem, .86rem + 17.86vw, 18rem);
  display: flex;
}

.env-empty-content {
  text-align: center;
}

.env-empty-text {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.env-form-container {
  grid-column: span 2 / span 2;
}

.env-form-wrapper {
  min-height: 100%;
  overflow: hidden;
}

.env-form-loading-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  display: flex;
}

.env-form-loading-content {
  align-items: center;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.env-form-loading-logo {
  width: auto;
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.env-form-loading-text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.env-form-loading-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.env-form-download-container {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.env-form-download-link {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(244 114 182 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  margin: .25rem;
  padding: .125rem .25rem;
}

.env-form-download-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity, 1));
}

.env-form-content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.env-form-input-wrapper {
  margin-top: 1.5rem;
  padding-top: 1rem;
  padding-bottom: .5rem;
}

.env-form-region-select {
  appearance: none;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  background-color: #0000;
  border-radius: .25rem;
  padding: .5rem .75rem;
  line-height: 1.25;
}

.env-form-region-select:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.env-form-submit-button {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding: .5rem 1rem;
  font-weight: 700;
}

.env-form-submit-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.env-content-container {
  height: 100%;
}

.env-content-header {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  display: flex;
  overflow-y: auto;
}

.env-content-header:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.env-content-logo {
  width: auto;
  height: 2rem;
  margin-bottom: .25rem;
}

.env-content-actions {
  align-items: center;
  display: flex;
}

.env-content-actions > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.env-content-button {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  padding: .25rem .75rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.env-content-button:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.env-content-refresh-button {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

.env-content-refresh-button:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  font-weight: 700;
}

.env-content-form-container {
  padding: 1rem;
}

.env-content-list-container {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
  padding-left: .5rem;
  padding-right: .5rem;
  display: grid;
}

.environment-header {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  display: flex;
  overflow: hidden auto;
}

.environment-header:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.environment-header-logo {
  width: auto;
  height: 2rem;
  margin-bottom: .25rem;
}

.environment-header-actions {
  align-items: center;
  display: flex;
}

.environment-header-actions > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.environment-header-button {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  padding: .25rem .75rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.environment-header-button:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.environment-refresh-button {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

.environment-refresh-button:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  font-weight: 700;
}

.environment-create-form-container {
  padding: 1rem;
}

.environment-list-container {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
  padding-left: .5rem;
  padding-right: .5rem;
  display: grid;
}

.product-main {
  width: 100%;
}

.home-section {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
  padding-top: .5rem;
}

.home-section:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 64rem) {
  .home-section > * {
    overflow: hidden;
  }
}

.overview-section {
  padding-top: 1.5rem;
  animation: 4s linear infinite alternate both color-change-2x;
  position: relative;
}

.overview-section:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

.features-section {
  animation: 4s linear infinite alternate both color-change-2x;
  position: relative;
}

.features-section:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

.pricing-section {
  align-items: center;
  animation: 4s linear infinite alternate both color-change-2x;
  position: relative;
}

.pricing-section:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

.pricing-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pricing-content {
  text-align: center;
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 40rem) {
  .pricing-content {
    max-width: 48rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 64rem) {
  .pricing-content {
    max-width: 80rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.contact-section {
  --tw-border-opacity: 1;
  border-top-width: 2px;
  border-top-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  padding-bottom: .5rem;
}

.contact-section:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #67e8f9 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.auth-loader-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: 4s linear infinite alternate both color-change-2x;
  display: flex;
}

.auth-loader-container:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

.auth-loader-content {
  cursor: pointer;
  align-items: center;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.auth-loader-logo {
  width: auto;
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.auth-loader-title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-top: 0;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

.auth-loader-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.auth-loader-version {
  vertical-align: middle;
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
  padding-left: .25rem;
  font-size: .8125rem;
  line-height: 1.5rem;
  display: inline;
}

.contact-image-container {
  position: relative;
}

@media (width >= 48rem) {
  .contact-image-container {
    width: 50%;
    position: absolute;
    left: 0;
  }
}

.contact-image {
  height: clamp(18rem, 13.71rem + 10.71vw, 24rem);
}

.contact-content-wrapper {
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

@media (width >= 40rem) {
  .contact-content-wrapper {
    max-width: 80rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 48rem) {
  .contact-content {
    width: 50%;
    margin-left: auto;
    padding-left: 2.5rem;
  }
}

.contact-matrix-wrapper {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
  border-radius: .25rem;
  margin: .5rem;
  padding: 1rem 3rem;
}

.contact-spacer {
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

@media (width >= 48rem) {
  .contact-spacer {
    padding-top: 3rem;
  }
}

.contact-title {
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

.contact-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .contact-title {
    letter-spacing: -.025em;
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.contact-description {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-top: 1rem;
  font-size: 1.25rem;
  line-height: 2rem;
}

.contact-description:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.contact-links {
  flex-wrap: wrap;
  gap: .5rem clamp(.5rem, .14rem + .89vw, 1rem);
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.contact-link {
  border-width: 1px;
  border-color: #0000;
  align-items: center;
  padding: .5rem .75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.contact-link-docs {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity, 1));
  border-width: 1px;
  border-color: #0000;
  align-items: center;
  padding: .5rem .75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.contact-link-docs:hover {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity, 1));
}

.contact-link-docs-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(99 102 241 / var(--tw-text-opacity, 1));
  margin-left: -.25rem;
  margin-right: .25rem;
}

.contact-link-youtube {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  border-width: 1px;
  border-color: #0000;
  align-items: center;
  padding: .5rem .75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.contact-link-youtube:hover {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
}

.contact-link-youtube:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.contact-link-youtube:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.contact-link-youtube-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(6 182 212 / var(--tw-text-opacity, 1));
  margin-left: -.25rem;
  margin-right: .25rem;
}

.contact-link-youtube-icon:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.contact-link-demo {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity, 1));
  border-width: 1px;
  border-color: #0000;
  align-items: center;
  padding: .5rem .75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.contact-link-demo:hover {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.contact-link-demo-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
  margin-left: -.25rem;
  margin-right: .25rem;
}

.contact-link-chat {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
  border-width: 1px;
  border-color: #0000;
  border-radius: .375rem;
  align-items: center;
  padding: .5rem .75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.contact-link-chat:hover {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
}

.contact-link-chat-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
  margin-left: -.25rem;
  margin-right: .25rem;
}

.contact-chat-arrow {
  --tw-text-opacity: 1;
  width: 1.5rem;
  height: 1.25rem;
  color: rgb(244 114 182 / var(--tw-text-opacity, 1));
  margin-top: .5rem;
}

.contact-aws-link {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  align-items: center;
  padding: clamp(.25rem, .07rem + .45vw, .5rem) clamp(.25rem, .89vw - .11rem, .75rem);
  font-size: clamp(.875rem, .696rem + .446vw, 1.125rem);
  line-height: clamp(1.5rem, 1.32rem + .45vw, 1.75rem);
  display: inline;
}

.contact-aws-link:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity, 1));
}

.contact-aws-image {
  height: clamp(1.25rem, .71rem + 1.34vw, 2rem);
  margin: .5rem;
  display: inline;
}

.features-container {
  align-items: center;
  position: relative;
}

.features-header {
  text-align: center;
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 40rem) {
  .features-header {
    max-width: 48rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 64rem) {
  .features-header {
    max-width: 80rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.features-title {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity, 1));
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.features-subtitle {
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity, 1));
  margin-top: .5rem;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

.features-subtitle:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .features-subtitle {
    letter-spacing: -.025em;
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.features-description {
  --tw-text-opacity: 1;
  max-width: 65ch;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  margin: 1.25rem auto 2.5rem;
  font-size: 1.25rem;
  line-height: 2rem;
}

.features-grid {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: grid;
}

@media (width >= 40rem) {
  .features-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 48rem) {
  .features-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 64rem) {
  .features-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 80rem) {
  .features-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.feature-card {
  cursor: pointer;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity, 1));
  border-radius: .5rem;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.feature-card:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity, 1));
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.feature-card:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity, 1));
}

.feature-image {
  object-fit: cover;
  width: 100%;
  height: 12rem;
}

.feature-content {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 1.5rem;
  display: flex;
}

.feature-category {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.feature-category:hover {
  text-decoration-line: underline;
}

.feature-category:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.feature-title {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
}

.feature-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.feature-preview {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  line-height: 1.75rem;
}

.feature-preview:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.quote-section {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #67e8f9 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

@media (width >= 64rem) {
  .quote-section {
    z-index: 10;
  }
}

.quote-container {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
}

@media (width >= 64rem) {
  .quote-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    display: grid;
  }
}

.quote-image-wrapper {
  margin-top: 8rem;
}

@media (width >= 48rem) {
  .quote-image-wrapper {
    margin-top: -3.5rem;
  }
}

@media (width >= 64rem) {
  .quote-image-wrapper {
    margin-top: -7rem;
  }
}

.quote-image-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

@media (width >= 64rem) {
  .quote-image-container {
    height: 100%;
  }
}

.quote-image {
  max-height: 24rem;
}

@media (width >= 40rem) {
  .quote-image {
    max-height: 20rem;
  }
}

@media (width >= 48rem) {
  .quote-image {
    max-height: 20rem;
  }
}

.quote-content {
  grid-column: span 2 / span 2;
}

@media (width >= 64rem) {
  .quote-content {
    margin: 0;
    padding-left: 2rem;
  }
}

.quote-text-wrapper {
  max-width: 28rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 40rem) {
  .quote-text-wrapper {
    max-width: 42rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 64rem) {
  .quote-text-wrapper {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.quote-text {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  margin-top: 1rem;
  font-weight: 500;
}

@media (width >= 40rem) {
  .quote-text {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

@media (width >= 48rem) {
  .quote-text {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (width >= 64rem) {
  .quote-text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (width >= 80rem) {
  .quote-text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.quote-mark {
  --tw-text-opacity: 1;
  width: 1.5rem;
  height: 1.5rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  opacity: .25;
  margin: .75rem;
  display: inline;
}

.nav-header {
  z-index: 10;
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  width: 100%;
  animation: 4s linear infinite alternate both color-change-2x;
  position: sticky;
  top: 0;
}

.nav-header:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

.nav-wrapper {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}

.nav-container {
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.nav-logo-section {
  align-items: center;
  width: 100%;
}

@media (width >= 48rem) {
  .nav-logo-wrapper {
    width: auto;
  }

  .nav-mobile-menu {
    display: none;
  }

  .nav-desktop-links {
    margin-left: 2.5rem;
    display: flex;
  }

  .nav-signin-wrapper {
    display: flex;
  }

  .nav-mobile-panel {
    display: none;
  }
}

.nav-header {
  z-index: 10;
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
  position: sticky;
  top: 0;
}

.nav-wrapper {
  padding: 0;
}

.nav-container {
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

@media (width >= 40rem) {
  .nav-container {
    padding-left: .75rem;
  }
}

.nav-logo-section {
  flex: 1;
  align-items: center;
  display: flex;
}

.nav-logo-wrapper {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (width >= 48rem) {
  .nav-logo-wrapper {
    width: auto;
  }
}

.nav-logo-image {
  height: 2.5rem;
  padding-left: .25rem;
  padding-right: .25rem;
  display: inline;
}

.nav-logo-text {
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  display: inline;
}

.nav-logo-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.nav-mobile-menu {
  align-items: center;
  margin-right: 1rem;
  display: flex;
}

@media (width >= 48rem) {
  .nav-mobile-menu {
    display: none;
  }
}

.nav-mobile-button {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  display: inline-flex;
}

.nav-mobile-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.nav-mobile-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.nav-mobile-button:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.nav-desktop-links {
  display: none;
}

.nav-desktop-links > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

@media (width >= 48rem) {
  .nav-desktop-links {
    margin-left: 2.5rem;
    display: flex;
  }
}

.nav-item-base {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  padding: .25rem .75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.nav-item-base:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}

.nav-item-active {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(236 72 153 / var(--tw-border-opacity, 1));
}

.nav-item-inactive:hover {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
}

.nav-signin-wrapper {
  padding-right: 1rem;
  display: none;
}

@media (width >= 48rem) {
  .nav-signin-wrapper {
    display: flex;
  }
}

.nav-signin-button {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.nav-signin-button:hover {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.nav-mobile-panel {
  transform-origin: top;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  padding: .5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.nav-mobile-panel:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 48rem) {
  .nav-mobile-panel {
    display: none;
  }
}

.nav-mobile-content {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
  --tw-ring-opacity: .05;
  border-radius: .5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  overflow: hidden;
}

.nav-mobile-header {
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
}

.nav-mobile-close {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  display: inline-flex;
}

.nav-mobile-close:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}

.nav-mobile-close:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity, 1));
  outline: 2px solid #0000;
}

.nav-mobile-close:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}

.nav-mobile-menu-items {
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;
}

.nav-mobile-links > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.nav-mobile-links {
  padding-left: .5rem;
  padding-right: .5rem;
}

.pricing-container {
  padding-bottom: 1rem;
}

.pricing-wrapper {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 40rem) {
  .pricing-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 64rem) {
  .pricing-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40rem) {
  .pricing-title-section {
    vertical-align: middle;
    flex-direction: column;
    display: flex;
  }
}

.pricing-title {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  margin-bottom: .25rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
}

.pricing-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .pricing-title {
    text-align: center;
  }
}

.pricing-grid {
  gap: 1rem;
  margin-top: .5rem;
}

.pricing-grid > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

@media (width >= 64rem) {
  .pricing-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    display: grid;
  }

  .pricing-grid > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}

@media (width >= 80rem) {
  .pricing-grid {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}

.pricing-card {
  border-width: 2px;
  border-radius: .5rem;
  margin-left: .5rem;
  margin-right: .5rem;
}

.pricing-card:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.pricing-card-header {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  padding: 1rem;
}

.pricing-card-header:is(.dark *) {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

.pricing-card-header-title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.pricing-card-features-container {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.pricing-card-features-list {
  margin-left: auto;
  margin-right: auto;
}

.pricing-card-features-list > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.pricing-card-features-list {
  padding-top: .5rem;
}

.pricing-card-feature-item {
  display: flex;
}

.pricing-card-feature-item > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.pricing-card-feature-icon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  flex-shrink: 0;
}

.pricing-card-feature-text {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.5rem;
}

.pricing-card-feature-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.pricing-card-action-container {
  align-items: center;
  width: 100%;
  padding-top: .5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pricing-card-price-container {
  text-align: center;
  margin-bottom: .25rem;
}

.pricing-card-price {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 2rem;
}

.pricing-card-price-label {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.pricing-card-action-text-button {
  cursor: pointer;
  vertical-align: text-bottom;
}

.pricing-card-action-text {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  line-height: 2rem;
}

.pricing-card-action-text:hover {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.pricing-card-action-link {
  cursor: pointer;
}

.pricing-card-action-link-text {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  line-height: 2rem;
}

.pricing-card-action-link-text:hover {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity, 1));
}

.pricing-card-developer {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.pricing-card-developer-header {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}

.pricing-card-production {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
}

.pricing-card-production-header {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}

.pricing-card-updates {
  --tw-border-opacity: 1;
  border-color: rgb(244 114 182 / var(--tw-border-opacity, 1));
}

.pricing-card-updates-header {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity, 1));
}

.hero-title-base {
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
}

.hero-title-base:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .hero-title-base {
    letter-spacing: -.025em;
    margin-top: 1.25rem;
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

@media (width >= 48rem) {
  .hero-title-base {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@media (width >= 64rem) {
  .hero-title-base {
    margin-top: 1.5rem;
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

@media (width >= 80rem) {
  .hero-title-base {
    letter-spacing: -.025em;
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

.hero-title-primary {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  display: block;
}

.hero-title-gradient {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #f3f4f6 var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  color: #0000;
  background-clip: text;
}

.hero-title-gradient:is(.dark *) {
  --tw-gradient-from: #e5e7eb var(--tw-gradient-from-position);
  --tw-gradient-to: #db2777 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.hero-subtitle-base {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
  margin-bottom: 1rem;
  font-size: clamp(1.125rem, 1.036rem + .223vw, 1.25rem);
  line-height: clamp(1.75rem, 1.57rem + .45vw, 2rem);
}

.hero-subtitle-base:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

@media (width >= 80rem) {
  .hero-subtitle-base {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.hero-cta-container {
  margin-top: .5rem;
  margin-left: 5rem;
  margin-right: 5rem;
  display: flex;
}

.hero-cta-wrapper {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
}

.hero-cta-button {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: #0000;
  border-color: rgb(244 114 182 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: .375rem;
  align-items: center;
  padding: .75rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: flex;
}

.hero-cta-button:hover {
  --tw-border-opacity: 1;
  border-color: rgb(249 168 212 / var(--tw-border-opacity, 1));
}

@media (width >= 48rem) {
  .hero-cta-button {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.hero-terms-container {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  margin-left: 4rem;
  margin-right: 4rem;
  font-size: .8125rem;
  line-height: 1.5rem;
}

.hero-terms-container:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.hero-terms-link {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.hero-terms-link:hover {
  text-decoration-line: underline;
}

.hero-main-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.hero-content-wrapper {
  display: inline-flex;
}

.hero-text-section {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: inline;
}

@media (width >= 40rem) {
  .hero-text-section {
    text-align: center;
    max-width: 42rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 64rem) {
  .hero-text-section {
    text-align: left;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
}

.hero-text-inner {
  padding-top: clamp(3rem, .86rem + 5.36vw, 6rem);
  padding-bottom: clamp(3rem, .86rem + 5.36vw, 6rem);
}

@media (width >= 40rem) {
  .hero-text-inner {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 48rem) {
  .hero-text-inner {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 80rem) {
  .hero-text-inner {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.hero-image-section {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 40rem) {
  .hero-image-section {
    max-width: 42rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 64rem) {
  .hero-image-section {
    padding-left: 0;
    padding-right: 0;
  }
}

.hero-background-image {
  opacity: .2;
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
}

@media (width >= 40rem) {
  .hero-background-image {
    opacity: .2;
  }
}

@media (width >= 48rem) {
  .hero-background-image {
    opacity: .8;
  }
}

.overview-container {
  text-align: center;
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 40rem) {
  .overview-container {
    max-width: 48rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 64rem) {
  .overview-container {
    max-width: 80rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.overview-text-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.overview-subtitle {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
}

.overview-subtitle:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
}

.overview-main-title {
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-top: .5rem;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

.overview-main-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

@media (width >= 40rem) {
  .overview-main-title {
    letter-spacing: -.025em;
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.overview-description {
  --tw-text-opacity: 1;
  max-width: 65ch;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  margin-top: .5rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25rem;
  line-height: 2rem;
}

.overview-screenshot-container {
  margin-top: 3rem;
  margin-bottom: -2.5rem;
}

@media (width >= 40rem) {
  .overview-screenshot-container {
    margin-bottom: -6rem;
  }
}

@media (width >= 64rem) {
  .overview-screenshot-container {
    margin-bottom: -16rem;
  }
}

.overview-screenshot {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
  --tw-ring-opacity: .05;
  border-radius: .5rem;
}

.tos-container {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  --tw-enter-opacity: 0;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
  border-radius: .125rem;
  padding: .5rem;
  font-size: .8125rem;
  line-height: 1.5rem;
  animation-name: enter;
  animation-duration: .15s;
}

.tos-container:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity, 1));
}

.tos-title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
}

.tos-subtitle {
  margin-top: .5rem;
}

.tos-section-title {
  margin-top: .5rem;
  font-weight: 700;
}

.tos-content {
  margin-top: .5rem;
}

.tos-contact-link {
  margin-bottom: 0;
}

.tos-modal-container {
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  padding: 1rem 1rem 5rem;
  display: flex;
}

@media (width >= 40rem) {
  .tos-modal-container {
    padding: 0;
    display: block;
  }
}

.tos-modal-overlay {
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .75;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  inset: 0;
}

.tos-modal-content {
  width: 75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  text-align: left;
  vertical-align: bottom;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  align-items: center;
  padding: 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation: 4s linear infinite alternate both color-change-2x;
  display: inline-block;
  position: relative;
  overflow: auto;
}

.tos-modal-content:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

@media (width >= 40rem) {
  .tos-modal-content {
    vertical-align: middle;
  }
}

.tos-modal-inner {
  align-items: center;
  padding-left: .25rem;
  padding-right: .25rem;
  display: inline-flex;
}

.tos-modal-body {
  padding-bottom: .5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  animation: 4s linear infinite alternate both color-change-2x;
}

.tos-modal-body:is(.dark *) {
  animation: 4s linear infinite alternate both color-change-2x-dark;
}

.tos-logo {
  width: auto;
  height: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.tos-logo-text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-top: 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
}

.tos-logo-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.tos-close-button {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .125rem;
  justify-content: center;
  margin-top: 1.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
}

.tos-close-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}

.tos-close-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@media (width >= 40rem) {
  .tos-close-button {
    grid-column-start: 1;
    margin-top: 0;
    font-size: .875rem;
    line-height: 1.5rem;
  }
}
/*# sourceMappingURL=index.e976d44d.css.map */
