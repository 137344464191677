/* Account Type Dropdown Styles using Tailwind @apply */

.account-dropdown-container {
  @apply relative w-full;
}

.account-dropdown-button {
  @apply relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm;
}

.account-dropdown-button-text {
  @apply block truncate text-gray-900;
}

.account-dropdown-button-icon-wrapper {
  @apply absolute inset-y-0 right-0 flex items-center pr-2;
}

.account-dropdown-button-icon {
  @apply h-5 w-5 text-gray-400;
}

.account-dropdown-options {
  @apply absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
}

.account-dropdown-option {
  @apply relative cursor-default select-none py-2 pl-10 pr-4;
}

.account-dropdown-option-active {
  @apply bg-amber-100 text-amber-900;
}

.account-dropdown-option-inactive {
  @apply text-gray-900;
}

.account-dropdown-option-text {
  @apply block truncate;
}

.account-dropdown-option-text-selected {
  @apply font-medium;
}

.account-dropdown-option-text-unselected {
  @apply font-normal;
}

.account-dropdown-check-icon-wrapper {
  @apply absolute inset-y-0 left-0 flex items-center pl-3;
}

.account-dropdown-check-icon-active {
  @apply text-amber-600;
}

.account-dropdown-check-icon-inactive {
  @apply text-gray-600;
}
.payment-modal-overlay {
  @apply fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
}

/* Modal Container */
.payment-modal-container {
  @apply fixed z-10 inset-0 overflow-y-auto;
}

.payment-modal-wrapper {
  @apply flex items-end sm:items-center justify-center 
         min-h-full p-4 text-center sm:p-0;
}

/* Modal Panel */
.payment-modal-panel {
  @apply relative bg-gray-50 rounded-lg px-4 pt-5 pb-4 
         text-left overflow-hidden shadow-xl transform 
         transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6;
}

/* Logo and Title */
.payment-modal-logo {
  @apply h-24 mx-auto w-auto;
}

.payment-modal-title {
  @apply text-2xl leading-6 font-medium text-gray-800;
}

/* Product Details */
.payment-modal-product-name {
  @apply text-lg leading-6 pb-2 text-gray-700;
}

.payment-modal-product-price {
  @apply text-2xl leading-6 pb-2 text-cyan-900;
}

.payment-modal-payment-title {
  @apply text-lg leading-6 pb-2 text-gray-700;
}

/* Submit Button */
.payment-modal-submit-button {
  @apply inline-flex mt-4 justify-center w-full 
         rounded-md border border-transparent shadow-sm 
         px-4 py-2 bg-cyan-500 text-base font-medium 
         text-white hover:bg-cyan-600 focus:outline-none 
         ring-1 ring-offset-0 ring-cyan-500 
         focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 
         sm:text-sm;
}

/* Go Back Button */
.payment-modal-back-button {
  @apply inline-flex justify-center w-full 
         rounded-md border border-transparent shadow-sm 
         px-4 py-2 bg-gray-500 text-base font-medium 
         text-white hover:bg-gray-600 focus:outline-none 
         ring-1 ring-offset-0 ring-gray-500 
         focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 
         sm:text-sm;
}
