/* Environment Card Styles */
.env-card {
  @apply relative dark:bg-gray-800 px-4 pt-6 pb-2 
         border-gray-400 rounded-lg overflow-hidden 
         hover:border-cyan-300 border-2 border-solid;
}

/* Loading State Styles */
.env-loading-container {
  @apply items-center animate-pulse;
}

.env-loading-logo {
  @apply h-14 mx-auto w-auto;
}

.env-loading-text {
  @apply mt-0 text-center text-xl font-bold text-gray-200;
}

/* Environment Header Styles */
.env-header {
  @apply flex;
}

.env-save-button {
  @apply px-2 rounded-md ml-1;
}

.env-save-icon {
  @apply h-5 w-7 text-gray-500 hover:text-cyan-400;
}

.env-delete-button {
  @apply text-xs text-gray-600 hover:bg-pink-500 
         hover:text-white dark:text-white 
         font-bold px-2 py-1;
}

.env-info {
  @apply pt-2 place-items-center;
}

.env-info-text {
  @apply px-2 text-center text-xs text-gray-400;
}

/* Stack Grid Styles */
.env-stack-grid {
  @apply grid grid-cols-3 gap-2 pt-4;
}

.env-stack-container {
  @apply text-center;
}

.env-stack-name {
  @apply text-lg text-gray-400 capitalize;
}

.env-stack-status {
  @apply text-xs text-cyan-400 overflow-hidden;
}

.env-stack-status-notfound {
  @apply text-xs text-cyan-400;
}

.env-stack-actions {
  @apply flex mt-1 items-center text-white 
         font-bold;
}

.env-stack-action-container {
  @apply w-full items-center mx-2 space-x-2;
}

.env-stack-delete-button {
  @apply px-2 py-1 bg-transparent dark:bg-gray-700 
         hover:bg-pink-500 rounded;
}

.env-stack-delete-icon {
  @apply h-5 w-5 text-pink-400;
}

.env-stack-deploy-button {
  @apply px-2 py-1 bg-transparent dark:bg-gray-700 
         hover:bg-cyan-500 rounded;
}

.env-stack-deploy-icon {
  @apply h-5 w-5 text-cyan-400;
}

/* Empty State Styles */
.env-empty-container {
  @apply flex items-center justify-center ~py-32/72;
}

.env-empty-content {
  @apply text-center;
}

.env-empty-text {
  @apply text-gray-400;
}
/* Environment Form Styles using Tailwind @apply */

/* Main Container Styles */
.env-form-container {
  @apply col-span-2;
}

.env-form-wrapper {
  @apply min-h-full overflow-hidden;
}

/* Loading State Styles */
.env-form-loading-container {
  @apply flex flex-col justify-center items-center min-h-full;
}

.env-form-loading-content {
  @apply items-center animate-pulse;
}

.env-form-loading-logo {
  @apply h-24 mx-auto w-auto;
}

.env-form-loading-text {
  @apply mt-0 text-center text-2xl font-bold text-gray-600 dark:text-gray-200;
}

/* Download Template Styles */
.env-form-download-container {
  @apply text-gray-400 mt-2 mb-2;
}

.env-form-download-link {
  @apply text-gray-600 py-0.5 border-2 border-pink-400 
         hover:bg-pink-400 rounded p-1 mx-1 my-1 
         cursor-pointer;
}

/* Form Content Styles */
.env-form-content {
  @apply space-y-2;
}

.env-form-input-wrapper {
  @apply mt-6 pb-2 pt-4;
}

.env-form-region-select {
  @apply border-2 appearance-none rounded w-full 
         py-2 px-3 bg-transparent border-gray-400 
         text-gray-400 leading-tight 
         focus:outline-none;
}

.env-form-submit-button {
  @apply w-full bg-cyan-400 hover:bg-cyan-500 
         text-white font-bold py-2 px-4 rounded;
}
.env-content-container {
  @apply h-full;
}

/* Header Styles */
.env-content-header {
  @apply flex items-center justify-between 
         bg-purple-500 dark:bg-cyan-400 
         py-1 overflow-y-auto mb-2 
         border-b-2 border-b-gray-400;
}

.env-content-logo {
  @apply h-8 w-auto mb-1;
}

.env-content-actions {
  @apply flex items-center space-x-2;
}

.env-content-button {
  @apply text-gray-200 text-xs hover:text-gray-100 
         px-3 py-1;
}

.env-content-refresh-button {
  @apply text-gray-200 hover:text-gray-100 
         hover:font-bold px-2 rounded;
}

/* Form Container Styles */
.env-content-form-container {
  @apply p-4;
}

/* List Container Styles */
.env-content-list-container {
  @apply grid grid-cols-4 gap-8 px-2;
}
.environment-header {
  @apply flex items-center overflow-x-hidden justify-between bg-purple-500 dark:bg-cyan-400 py-1 overflow-y-auto mb-2 border-b-2 border-b-gray-400;
}

.environment-header-logo {
  @apply h-8 w-auto mb-1;
}

.environment-header-actions {
  @apply flex items-center space-x-2;
}

.environment-header-button {
  @apply text-gray-200 text-xs hover:text-gray-100 px-3 py-1;
}

.environment-refresh-button {
  @apply text-gray-200 hover:text-gray-100 hover:font-bold px-2 rounded;
}

.environment-create-form-container {
  @apply p-4;
}

.environment-list-container {
  @apply grid grid-cols-4 gap-8 px-2;
}
