/* Workflow Builder Styles */

.modal-container {
  @apply fixed inset-0 z-40 overflow-y-auto;
}

.modal-wrapper {
  @apply flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0;
}

.modal-overlay {
  @apply fixed inset-0 bg-black bg-opacity-35;
}

.modal-content {
  @apply inline-block h-3/4 w-3/4 transform overflow-hidden rounded-lg border-2 border-cyan-400 shadow-xl transition-all sm:my-8 sm:align-middle animate-color-change-2x dark:animate-color-change-2x-dark;
}

.modal-header {
  @apply flex items-start border-b-2 border-cyan-400 pb-4;
}

.modal-header-icon {
  @apply flex h-10 w-10 items-center justify-center;
}

.modal-title {
  @apply ml-3 text-2xl font-medium text-gray-600 dark:text-gray-100;
}
.templates-grid {
  @apply mt-2 grid grid-cols-3 gap-2 overflow-y-auto border-2 border-gray-400 rounded bg-gray-300 p-4 dark:bg-gray-800 h-80;
}

.template-card {
  @apply h-24 w-full rounded-md border-2 border-gray-500 bg-gray-400 p-2 hover:border-cyan-400 dark:bg-gray-700;
}

.template-header {
  @apply flex items-center border-b-2 border-gray-500 pb-1;
}

.template-icon-wrapper {
  @apply rounded-full border-2 border-cyan-400 text-cyan-400 p-1 ;
}

.template-name {
  @apply ml-2 text-lg text-cyan-300 dark:text-cyan-400;
}

/* Flow Display */
.flow-container {
  @apply mt-2 flex items-center justify-between;
}

.flow-steps {
  @apply flex items-center space-x-0;
}

.flow-step {
  @apply rounded-l border-2 border-gray-500 px-2 py-1 text-2xs text-cyan-400 bg-gray-500 dark:bg-gray-800;
}

.flow-step-end {
  @apply rounded-r border-2 border-gray-500 px-2 py-1 text-2xs text-cyan-400 bg-gray-500 dark:bg-gray-800;
}

.flow-arrow {
  @apply h-4 w-5 bg-gray-500 text-cyan-400;
}

/* Buttons */
.apply-button {
  @apply rounded border-2 border-cyan-400 bg-cyan-400 px-2 py-0 text-xs text-white hover:bg-cyan-300;
}

.cancel-button {
  @apply rounded border border-gray-300 px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 dark:text-gray-100;
}

.save-button {
  @apply rounded-md border border-transparent bg-pink-600 px-4 py-2 text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2;
}

/* Footer */
.modal-footer {
  @apply flex justify-end space-x-3 px-6 py-4;
}

.heading {
  @apply text-xl leading-6 font-semibold text-gray-600 dark:text-cyan-300;
}

/* Toggle Switch */
.toggle-switch {
  @apply relative inline-flex flex-shrink-0 h-3 w-7 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400;
}

.toggle-switch-enabled {
  @apply bg-cyan-400;
}

.toggle-switch-disabled {
  @apply bg-gray-700;
}

.toggle-handle {
  @apply pointer-events-none inline-block h-2 w-2 rounded-full bg-gray-500 shadow transform ring-0 transition ease-in-out duration-200;
}

.toggle-handle-enabled {
  @apply translate-x-4;
}

.toggle-handle-disabled {
  @apply translate-x-0;
}

/* Dropdown Styles */
.dropdown-container {
  @apply px-1 relative w-full;
}

.dropdown-icon {
  @apply h-5 w-5 mt-0.5 mr-1;
}

.dropdown-caret {
  @apply ml-0.5 -mr-1 mt-1 h-4 w-4;
}

.dropdown-menu {
  @apply absolute z-10 mt-2 w-44 rounded-sm shadow-lg bg-white ring-1 ring-gray-400 ring-opacity-5;
}

.dropdown-menu-content {
  @apply py-3;
}

.dropdown-item {
  @apply block w-full text-left px-4 py-2 text-sm text-cyan-300 hover:text-white dark:text-gray-700 hover:bg-cyan-300;
}

.dropdown-button {
  @apply inline-flex w-full px-2 text-gray-200 bg-transparent focus:outline-none text-lg;
}

.subnav-container {
  @apply flex-shrink-0 h-full bg-gray-700/70;
}

.subnav-wrapper {
  @apply min-w-36 max-w-44 h-full bg-gray-700/70 border-r-2 border-r-purple-600 border-t-purple-500 border-b-cyan-500 dark:bg-cyan-400;
}

.subnav-buttons-container {
  @apply items-start place-items-start px-2 space-y-1 py-1;
}

.subnav-divider {
  @apply border-l-2 border-l-gray-400 dark:border-l-gray-700 place-items-start px-2 pt-2 pb-1;
}

.subnav-divider-small {
  @apply border-l-2 border-l-gray-400 dark:border-l-gray-700 place-items-start px-2 pb-1;
}

.sftp-modal-body {
  @apply px-4 pt-5 pb-4 animate-color-change-2x sm:p-6 sm:pb-4;
}

.sftp-modal-header {
  @apply sm:flex sm:items-start;
}

.sftp-modal-icon-wrapper {
  @apply mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full animate-color-change-2x sm:mx-0 sm:h-10 sm:w-10;
}

.sftp-modal-icon {
  @apply h-9 w-9 text-gray-100;
}

.sftp-modal-title-wrapper {
  @apply mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left;
}

.sftp-modal-title {
  @apply mt-2 text-2xl leading-6 font-medium text-gray-100;
}

.sftp-form-container {
  @apply mt-5 space-y-5;
}

.sftp-textarea-wrapper {
  @apply relative border border-gray-500 rounded px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-400 focus-within:border-cyan-400;
}

.sftp-textarea-label {
  @apply z-10 absolute -top-2 left-2 -mt-px inline-block px-1 bg-gray-700 rounded-md text-xs font-medium text-gray-100;
}

.sftp-textarea {
  @apply block bg-transparent w-full border-0 p-0 h-auto text-cyan-400 placeholder-cyan-200 focus:ring-0 sm:text-sm;
}

.sftp-modal-footer {
  @apply px-4 py-3 animate-color-change-2x sm:px-6 sm:flex sm:flex-row-reverse;
}

.sftp-save-button {
  @apply w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-600 text-base font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 sm:w-auto sm:text-sm;
}

.sftp-cancel-button {
  @apply mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-transparent text-base font-medium text-gray-100 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm;
}
/* Target Value Modal Styles */
.target-modal-container {
  @apply fixed z-10 inset-0 overflow-y-auto;
}

.target-modal-wrapper {
  @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
}

.target-modal-overlay {
  @apply fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
}

.target-modal-content {
  @apply relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6;
}

.target-modal-header {
  @apply px-1 pb-6 inline-flex;
}

.target-modal-icon-wrapper {
  @apply absolute top-4 left-4 px-1 inline;
}

.target-modal-icon {
  @apply h-10 w-10 text-gray-500 bg-white rounded-md inline;
}

.target-modal-title {
  @apply text-xl font-medium text-gray-500 inline;
}

.target-modal-subtitle {
  @apply text-xs leading-6 ml-2 text-gray-500;
}

.target-form-container {
  @apply mt-2 sm:mt-6 border border-gray-300 bg-white p-4 rounded-md shadow-sm;
}

.target-input {
  @apply shadow-sm focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-sm border-gray-300 rounded-md col-span-2;
}

.target-divider-wrapper {
  @apply relative py-4;
}

.target-divider-line {
  @apply absolute inset-0 flex items-center;
}

.target-divider-border {
  @apply w-full border-t border-gray-300;
}

.target-divider-text {
  @apply relative flex justify-center;
}

.target-divider-label {
  @apply px-2 bg-white text-sm text-gray-500;
}

.target-lookup-grid {
  @apply sm:grid sm:grid-cols-6 sm:gap-2 sm:grid-flow-row-dense bg-white p-2;
}

.target-button-grid {
  @apply mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-2 sm:grid-flow-row-dense;
}

.target-button-base {
  @apply mt-3 w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:text-sm;
}

.target-close-button {
  @apply target-button-base border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:ring-cyan-500 sm:col-start-1;
}

.target-clear-button {
  @apply target-button-base border-orange-400 bg-white text-gray-700 hover:bg-orange-500 focus:ring-orange-500 sm:col-start-2;
}

.target-action-button {
  @apply inline justify-center rounded-md border border-transparent shadow-sm bg-gray-600 text-base font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-lg;
}
/* Time Dropdown Styles */
.time-dropdown-container {
  @apply relative border border-gray-500 rounded px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-400 focus-within:border-cyan-400;
}

.time-dropdown-label {
  @apply z-10 absolute -top-2 left-2 -mt-px inline-block px-1 bg-gray-700 rounded-md text-xs font-medium text-gray-100;
}

.time-dropdown-wrapper {
  @apply mr-3 whitespace-nowrap relative z-50;
}

.time-dropdown-button {
  @apply bg-gray-800 text-gray-100 align-middle relative w-full border border-gray-600 rounded shadow-sm pl-3 pr-12 py-2 text-left text-sm cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-300 focus:border-cyan-300;
}

.time-dropdown-icon {
  @apply absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none;
}

.time-dropdown-options {
  @apply align-middle w-full bg-gray-800 text-gray-200 absolute shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm;
}

.time-dropdown-option {
  @apply cursor-default select-none relative py-2 pl-3 pr-9;
}

.time-dropdown-option-active {
  @apply text-white bg-cyan-600;
}

.time-dropdown-option-inactive {
  @apply text-gray-200;
}

.time-dropdown-option-text {
  @apply block truncate;
}

.time-dropdown-check-icon {
  @apply absolute inset-y-0 right-0 flex items-center pr-4;
}

.time-dropdown-check-icon-active {
  @apply text-white;
}

.time-dropdown-check-icon-inactive {
  @apply text-cyan-400;
}
/* URL Input Box Styles */
.url-input-container {
  @apply flex rounded shadow-sm relative text-gray-600;
}

.url-input-prefix {
  @apply inline-flex rounded-l-md text-gray-600 items-center px-3 border border-r border-gray-400 dark:text-gray-200 sm:text-sm;
}

.url-input-label {
  @apply absolute -top-4 left-2 dark:bg-gray-800 -mt-px inline-block rounded px-1 bg-gray-100 text-lg dark:text-gray-200;
}

.url-input-base {
  @apply flex-1 bg-transparent block focus:ring-cyan-500 focus:border-cyan-500 min-w-0 sm:text-sm dark:text-cyan-400 text-gray-600;
}

.url-input-with-prefix {
  @apply url-input-base rounded-r-md border-2 animate-color-change-2x dark:animate-color-change-2x-dark border-y dark:border-y-cyan-400 dark:border-y border-r border-gray-400 dark:border-r-cyan-400 dark:border-r;
}

.url-input-without-prefix {
  @apply url-input-base w-full rounded-md;
}

/* Workflow Dropdown Styles */
.workflow-dropdown-wrapper {
  @apply mr-3 whitespace-nowrap relative z-10;
}

.workflow-dropdown-button {
  @apply bg-transparent dark:bg-gray-800 text-gray-600 dark:text-gray-100 align-middle relative w-full border border-gray-600 rounded shadow-sm pl-3 pr-12 py-2 text-left text-sm cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-300 focus:border-cyan-300;
}

.workflow-dropdown-text {
  @apply text-sm truncate;
}

.workflow-dropdown-icon-wrapper {
  @apply absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none;
}

.workflow-dropdown-options {
  @apply align-middle w-full bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-100 absolute shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm;
}

.workflow-dropdown-option {
  @apply cursor-default select-none relative py-2 pl-3 pr-9;
}

.workflow-dropdown-option-active {
  @apply text-white bg-cyan-400;
}

.workflow-dropdown-option-inactive {
  @apply text-gray-600 dark:text-gray-200;
}

.workflow-dropdown-check {
  @apply absolute inset-y-0 right-0 flex items-center pr-4;
}

.workflow-dropdown-check-active {
  @apply text-white;
}

.workflow-dropdown-check-inactive {
  @apply text-cyan-400;
}
/* Workflow Name Input Styles */
.workflow-input-container {
  @apply relative border-2 flex border-cyan-300 rounded px-2 py-1.5 focus-within:ring-cyan-600 focus-within:border-pink-600;
}

.workflow-input {
  @apply block w-full border-0 p-0 text-gray-600 dark:text-cyan-200 placeholder-gray-400 bg-transparent dark:bg-gray-800 focus:ring-0 sm:text-sm;
}

/* Adapters Layout */
.adapters-layout {
  @apply w-full h-full flex flex-col;
}

.adapters-main {
  @apply p-4 flex-1 overflow-y-auto overflow-x-hidden;
}

.adapters-card {
  @apply bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg border-2 border-gray-300 dark:border-gray-700;
}

.adapters-content {
  @apply p-4 space-y-6;
}

/* Name Section */
.adapters-name-wrapper {
  @apply flex items-center gap-4;
}

.adapters-name-container {
  @apply flex items-center flex-1 gap-2;
}

.adapters-icon-container {
  @apply flex-shrink-0;
}

.adapters-icon-button {
  @apply border-2 rounded-md text-cyan-500 px-3 py-2 border-gray-500 hover:border-cyan-400 
         transition-colors duration-200 cursor-pointer hover:text-cyan-300;
}

.adapters-name-input-wrapper {
  @apply flex-1 min-w-0;
}

/* Status Button */
.adapters-status-button {
  @apply px-4 py-2 text-sm font-medium rounded-md transition-colors duration-200 
         focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.adapters-status-active {
  @apply bg-green-100 text-green-700 border-green-400 
         hover:bg-green-200 focus:ring-green-500;
}

.adapters-status-inactive {
  @apply bg-purple-100 text-purple-700 border-purple-400 
         hover:bg-purple-200 focus:ring-purple-500;
}

/* Description Section */
.adapters-description {
  @apply space-y-4;
}

.adapters-description-text {
  @apply text-base text-gray-600 dark:text-gray-200 leading-relaxed;
}

/* Adapter Selection */
.adapters-selection {
  @apply border-2 border-purple-500 rounded-lg p-6;
}

.adapters-selection-content {
  @apply flex items-center justify-center gap-6 flex-wrap;
}

.adapters-dropdown-container {
  @apply w-full max-w-sm;
}

.adapters-dropdown-title {
  @apply text-lg font-medium text-gray-700 dark:text-gray-100 mb-2;
}

.adapters-arrow {
  @apply h-8 w-8 text-pink-500 dark:text-purple-500 flex-shrink-0 mt-8;
}

/* Sample Message */
.adapters-sample {
  @apply space-y-4;
}

.adapters-sample-header {
  @apply flex items-center;
}

.adapters-sample-container {
  @apply border-2 border-cyan-300 rounded-lg p-4 transition-shadow duration-200
         hover:shadow-lg focus-within:ring-2 focus-within:ring-cyan-400 focus-within:border-cyan-400;
}

.adapters-sample-content {
  @apply relative;
}

.adapters-sample-textarea {
  @apply w-full min-h-[200px] text-sm text-gray-700 dark:text-cyan-400 
         bg-transparent dark:bg-gray-800 border-0 resize-y
         focus:ring-0 placeholder-gray-400 dark:placeholder-gray-500;
}

/* ELayer Layout */
.elayer-container {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.elayer-content {
  @apply p-4 space-y-6 flex-1 overflow-y-auto;
}

/* Message Section */
.elayer-message-section {
  @apply space-y-4;
}

.elayer-message-heading {
  @apply flex;
}

.elayer-message-container {
  @apply bg-gray-100 dark:bg-gray-900 border-2 border-gray-200 dark:border-gray-700 rounded-lg dark:hover:border-cyan-600 transition-all duration-200 py-2 px-0;
}

.elayer-message-content {
  @apply flex px-2 justify-between gap-4;
}

.elayer-message-text {
  @apply flex min-w-0;
}

.elayer-message-info {
  @apply text-gray-700 dark:text-gray-200 text-sm break-all;
}

.elayer-message-warning {
  @apply text-amber-500 dark:text-amber-400 text-xs ml-2;
}

.elayer-message-note {
  @apply text-cyan-500 dark:text-cyan-400 text-xs ml-2;
}

/* Copy Button */
.elayer-copy-button {
  @apply flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-md
         bg-purple-500 text-white hover:bg-purple-600 
         dark:bg-purple-600 dark:hover:bg-purple-700
         transition-colors duration-200 focus:outline-none focus:ring-2 
         focus:ring-offset-2 focus:ring-purple-500;
}

.elayer-copy-icon {
  @apply h-4 w-4 text-white;
}

/* Webhook Section */
.elayer-webhook-container {
  @apply space-y-2;
}

.elayer-webhook-label {
  @apply block text-sm font-medium text-gray-700 dark:text-gray-200;
}

/* Filter Section */
.elayer-filter-container {
  @apply mt-2;
}

.elayer-filter-content {
  @apply bg-gray-100 dark:bg-gray-800 rounded shadow border-2 border-gray-300 dark:border-gray-700;
}

/* Table Base */
/* Table Base */
.filter-table {
  @apply w-full table-auto border-separate border-spacing-0;
}

/* Filter Cell Styles */
.table-cell-filter {
  @apply px-6 py-4 text-sm text-gray-500 dark:text-gray-400;
  width: 20%;
  background: inherit;
}
/* Table Header Styles */
.filter-header {
  @apply bg-gray-50 dark:bg-gray-900;
}

.filter-header-row {
  @apply flex items-center justify-between p-4 min-h-[4rem] w-full;
}

.filter-header-content {
  @apply flex items-center justify-between w-full;
}

.filter-title-section {
  @apply flex items-center;
}

.filter-title {
  @apply text-lg font-medium text-gray-900 dark:text-white whitespace-nowrap;
}

.filter-controls {
  @apply flex items-center justify-end gap-4 ml-auto;
}

/* View Toggle */
.view-toggle-container {
  @apply flex items-center gap-2 text-sm text-gray-700 dark:text-gray-200 whitespace-nowrap;
}

/* SFTP Config Button */
.sftp-config-button {
  @apply inline-flex items-center gap-2 px-3 py-2 text-sm font-medium rounded-md
         bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200
         hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer
         transition-colors duration-200 whitespace-nowrap;
}

.sftp-config-icon {
  @apply h-4 w-4;
}

.sftp-config-text {
  @apply text-sm;
}

.filter-divider {
  @apply h-px bg-gray-200 dark:bg-gray-700 mt-2;
}

/* Column Headers */
.table-header-cell-first {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 
         uppercase tracking-wider;
  width: 7%;
  background: inherit;
}

.table-header-cell {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 
         uppercase tracking-wider;
  background: inherit;
}

.table-header-cell:nth-child(2) {
  width: 23%;
}

.table-header-cell:nth-child(3) {
  width: 50%;
}

.table-header-cell:nth-child(4) {
  width: 20%;
}

/* Info Icon */
.table-header-icon {
  @apply h-4 w-4 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 cursor-pointer;
}
/* Filter Input Container */
.filter-input-container {
  @apply flex items-center gap-2 w-full;
}
.filter-header-row {
  @apply flex items-center justify-between p-4 min-h-[4rem] w-full;
}

.filter-header-content {
  @apply flex items-center justify-between w-full;
}

.filter-title-section {
  @apply flex items-center flex-1;
}

.filter-title {
  @apply text-lg font-medium text-gray-900 dark:text-white whitespace-nowrap;
}

.filter-controls {
  @apply flex items-center justify-end gap-4 ml-auto;
}

/* SFTP Config Button */
.sftp-config-button {
  @apply inline-flex items-center gap-2 px-3 py-2 text-sm font-medium rounded-md
         bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200
         hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer
         transition-colors duration-200 whitespace-nowrap;
}

/* View Toggle Container */
.view-toggle-container {
  @apply flex items-center gap-2 text-sm text-gray-700 dark:text-gray-200 whitespace-nowrap ml-auto;
}

.filter-divider {
  @apply h-px bg-gray-200 dark:bg-gray-700 mt-2;
}
/* Filter Input */
.filter-input {
  @apply flex-1 px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 
         rounded-md focus:ring-purple-500 focus:border-purple-500
         dark:bg-gray-700 dark:text-white;
}

/* Delete Button */
.filter-delete {
  @apply flex-shrink-0 p-1.5 text-gray-400 hover:text-red-500 dark:hover:text-red-400 
         cursor-pointer rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 
         transition-colors duration-200;
}

.filter-delete-icon {
  @apply h-5 w-5;
}

/* SFTP Config */
.sftp-config-button {
  @apply flex items-center gap-2 px-3 py-2 text-sm font-medium rounded-md
         bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200
         hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer
         transition-colors duration-200;
}

.sftp-config-icon {
  @apply h-4 w-4;
}

.sftp-config-text {
  @apply text-sm;
}

/* View Toggle */
.view-toggle-container {
  @apply flex items-center gap-2 text-sm text-gray-700 dark:text-gray-200;
}

/* Info Icon */
.table-header-icon {
  @apply h-4 w-4 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 cursor-pointer;
}

/* Table Rows */
.table-row {
  @apply border-b border-gray-200 dark:border-gray-700;
}

.table-row-even {
  @apply bg-gray-50 dark:bg-gray-700 hover:bg-cyan-500/10;
}

.table-row-odd {
  @apply bg-white dark:bg-gray-800 hover:bg-cyan-500/10;
}

.table-row-success {
  @apply bg-white dark:bg-gray-800 border-2 border-solid border-green-400 hover:bg-cyan-500/10;
}

.table-row-error {
  @apply bg-white dark:bg-gray-800 border-2 border-solid border-pink-400 hover:bg-pink-500/10;
}

/* Table Cells */
.table-cell {
  @apply px-2 py-2 text-sm text-gray-600 dark:text-gray-200;
}

.table-cell-first {
  @apply pl-4 ~pr-16/24 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-200;
}

.table-cell-spec {
  @apply px-2 py-2 whitespace-normal text-xs font-light text-gray-600 dark:text-gray-200;
}

.table-cell-sample {
  @apply px-2 py-2 whitespace-normal text-xs text-gray-600 dark:text-gray-200;
}

.table-cell-filter {
  @apply px-2 py-2 flex text-sm text-gray-600 dark:text-gray-200 w-full;
}

/* Filter Input */
.filter-input {
  @apply shadow-sm flex focus:ring-cyan-300 focus:border-cyan-300 block w-full sm:text-sm border-cyan-300 border-2 rounded-md dark:bg-gray-800;
}

.filter-delete {
  @apply pl-2 pt-1 inline rounded-md;
}

.filter-delete-icon {
  @apply h-8 w-8 inline cursor-pointer text-gray-400 hover:text-pink-400;
}


.delete-button {
  @apply pl-2 pt-1 inline rounded-md;
}

.delete-icon {
  @apply h-8 w-8 inline cursor-pointer text-gray-400 hover:text-pink-400;
}

/* Message ID Rows */
.message-id-row-match {
  @apply dark:bg-gray-800 border-2 border-solid border-green-400 hover:bg-cyan-500;
}

.message-id-row-mismatch {
  @apply dark:bg-gray-800 border-2 border-solid border-pink-400 hover:bg-pink-500;
}

/* ELayer Component Styles */
.elayer-container {
  @apply py-0 w-full overflow-y-scroll;
}

.elayer-content {
  @apply inline overflow-y-scroll grow;
}

/* Message Address Section */
.elayer-message-section {
  @apply mx-1;
}

.elayer-message-heading {
  @apply text-cyan-500 dark:bg-gray-800;
}

.elayer-message-container {
  @apply relative rounded p-4 w-full;
}

.elayer-message-content {
  @apply flex;
}

.elayer-message-text {
  @apply text-xl dark:text-cyan-500 text-gray-600 flex-1 w-full text-center;
}

.elayer-message-info {
  @apply text-sm py-2 dark:text-cyan-400 text-gray-600;
}

.elayer-message-warning {
  @apply text-sm text-red-400;
}

.elayer-message-note {
  @apply text-pink-400;
}

.elayer-copy-button {
  @apply text-xs text-gray-600 dark:text-gray-200 hover:text-cyan-300 px-2;
}

.elayer-copy-icon {
  @apply h-4 w-4 pt-0 mt-0 dark:text-cyan-400 text-gray-600 inline;
}

/* Webhook URL Section */
.elayer-webhook-container {
  @apply relative flex border border-gray-300 rounded px-2 py-2 shadow-sm;
}

.elayer-webhook-label {
  @apply absolute rounded -top-4 left-2 -mt-px inline-block px-1 bg-gray-100 dark:bg-gray-800 text-xs font-medium text-gray-600 dark:text-gray-200 z-10;
}

/* Filter Section */
.elayer-filter-container {
  @apply sm:rounded-md sm:overflow-hidden;
}

.elayer-filter-content {
  @apply rounded-sm space-y-4 pb-6;
}

.elayer-filter-table {
  @apply w-full divide-y divide-cyan-400 shadow;
}

.elayer-table-header {
  @apply dark:bg-gray-800;
}

.elayer-table-title-row {
  @apply flex justify-between items-center px-3 pt-2;
}

.elayer-table-title {
  @apply text-xl font-semibold text-gray-600 dark:text-cyan-400;
}

.elayer-table-controls {
  @apply flex items-center space-x-4;
}

.elayer-sftp-button {
  @apply flex items-center border-2 rounded-md px-2 border-gray-200 cursor-pointer hover:bg-cyan-500;
}

.elayer-sftp-icon {
  @apply h-5 w-5 dark:text-gray-200 text-gray-600;
}

.elayer-sftp-text {
  @apply text-sm dark:text-gray-200 text-gray-600 px-2 py-1;
}

.elayer-toggle-container {
  @apply flex items-center space-x-2 py-1 text-sm text-gray-600 dark:text-gray-100 dark:bg-gray-800;
}

.elayer-table-divider {
  @apply border-t border-t-cyan-400;
}

/* Table Headers */
.elayer-th-base {
  @apply px-2 py-2 text-left text-sm font-medium text-cyan-300 tracking-wider;
}

.elayer-th-field {
  @apply pl-4 pr-2 py-2 text-left text-sm font-medium text-cyan-300 uppercase tracking-wider;
}

.elayer-info-icon {
  @apply h-5 w-5 pb-1 inline align-middle text-pink-300 rounded cursor-pointer hover:text-pink-500;
}

/* Table Row Styles */
.elayer-tr-even {
  @apply bg-gray-100 dark:bg-gray-700 hover:bg-cyan-500;
}

.elayer-tr-odd {
  @apply dark:bg-gray-800 hover:bg-cyan-500;
}

.elayer-tr-success {
  @apply dark:bg-gray-800 border-2 border-solid border-green-400 hover:bg-cyan-500;
}

.elayer-tr-error {
  @apply dark:bg-gray-800 border-2 border-solid border-pink-400 hover:bg-pink-500;
}

/* Table Cell Styles */
.elayer-td-field {
  @apply pl-4 pr-2 py-2 whitespace-nowrap text-sm dark:text-gray-200 text-gray-600;
}

.elayer-td-spec {
  @apply px-2 py-2 whitespace-normal text-xs font-light dark:text-gray-200 text-gray-600;
}

.elayer-td-sample {
  @apply px-2 py-2 whitespace-normal text-xs dark:text-gray-200 text-gray-600;
}

.elayer-td-filter {
  @apply px-2 py-2 whitespace-normal flex text-sm dark:text-gray-200 text-gray-600;
}

.elayer-filter-input {
  @apply shadow-sm flex focus:ring-cyan-300 focus:border-cyan-300 block w-full sm:text-sm border-cyan-300 border-2 rounded dark:bg-gray-800;
}

.elayer-delete-button {
  @apply pl-2 pt-1 inline rounded-md;
}

.elayer-delete-icon {
  @apply h-8 w-8 inline cursor-pointer text-gray-400 hover:text-pink-400;
}

/* TLayer Layout */
.tlayer-container {
  @apply w-full overflow-y-scroll;
}

.tlayer-content {
  @apply sm:rounded-md sm:overflow-hidden p-4 space-y-6 flex-1 overflow-y-auto;
}

.tlayer-section {
  @apply bg-gray-100 dark:bg-gray-800 space-y-6 rounded-md border-2 border-gray-200 dark:border-gray-700;
}

/* Data Map Table */
.data-map-table {
  @apply min-w-full shadow divide-y divide-cyan-400 border-2 border-gray-300 dark:border-gray-700;
}

.data-map-header {
  @apply bg-transparent dark:bg-gray-800;
}

.data-map-title-row {
  @apply flex justify-between items-center px-4 py-2;
}

.data-map-title {
  @apply text-xl font-semibold text-gray-600 mt-2 dark:text-cyan-400;
}

.data-map-controls {
  @apply flex items-center space-x-4;
}

.fhir-button {
  @apply inline-flex items-center mx-1 mr-2 text-gray-500 hover:text-gray-200;
}

.fhir-button-loading {
  @apply relative px-2 py-1 border-2 border-pink-500 rounded-md shadow-sm animate-wiggle focus:outline-none cursor-pointer;
}

.fhir-progress-bar {
  @apply absolute bottom-0 left-0 w-full bg-pink-500/80;
}

.fhir-button-idle {
  @apply px-2 py-1 border-2 border-cyan-400 hover:bg-cyan-400 rounded-md shadow-sm bg-transparent focus:outline-none cursor-pointer;
}

.fhir-icon {
  @apply h-6 w-8 inline cursor-pointer text-gray-600 dark:text-gray-200;
}

.toggle-container {
  @apply flex items-center space-x-2 text-sm text-gray-600 dark:text-gray-100 dark:bg-gray-800;
}

.table-divider {
  @apply border-t border-t-cyan-400;
}

/* Table Headers */
.table-header {
  @apply px-4 py-2 text-left text-xs font-medium text-cyan-300 uppercase tracking-wider;
}

.table-header-sample {
  @apply px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}

/* Table Rows */
.table-row-even {
  @apply bg-gray-100 dark:bg-gray-700 hover:bg-cyan-500;
}

.table-row-odd {
  @apply dark:bg-gray-800 hover:bg-cyan-500;
}

/* Table Cells */
.table-cell {
  @apply pl-4 pr-2 pt-1 whitespace-nowrap text-sm dark:text-gray-200;
}

.table-cell-spec {
  @apply px-2 pb-2 pt-1 whitespace-normal text-xs truncate font-light dark:text-gray-200;
}

.table-cell-sample {
  @apply px-2 pb-2 pt-1 whitespace-normal text-sm dark:text-gray-200;
}

.table-cell-actions {
  @apply px-2 py-1 whitespace-normal flex w-full text-sm dark:text-gray-100;
}

/* Input Fields */
.table-input {
  @apply dark:bg-gray-800 flex focus:ring-cyan-300 focus:border-cyan-300 w-full sm:text-sm border-cyan-300 border-2 rounded;
}

/* Action Buttons */
.action-button {
  @apply pl-2 mt-1 inline rounded-md;
}

.cog-icon {
  @apply h-8 w-8 inline cursor-pointer text-gray-500 hover:text-cyan-400;
}

.delete-button {
  @apply pl-0 ml-1 pl-1 pt-1 inline rounded-md;
}

.delete-icon {
  @apply h-8 w-8 inline cursor-pointer text-gray-500 rounded hover:text-pink-400;
}

/* Loading State */
.loading-container {
  @apply px-2 md:mt-0 md:col-span-7;
}

.loading-content {
  @apply bg-gray-800 rounded-md space-y-6 p-6 mx-6 border-solid border shadow border-cyan-300;
}

.loading-header {
  @apply inline-flex w-full;
}

.loading-toggle {
  @apply inline-flex items-center px-2 py-1 mx-1 mr-2 border border-cyan-400 text-gray-200 rounded-md shadow-sm text-xs bg-gray-800 focus:outline-none;
}

.loading-animation {
  @apply items-center animate-pulse py-48 sm:py-24 md:py-32 lg:py-48;
}

.loading-logo {
  @apply h-24 mx-auto w-auto;
}

.loading-title {
  @apply mt-0 text-center text-2xl font-bold text-gray-200;
}

.loading-version {
  @apply inline align-middle text-xs tracking-tight text-cyan-300 pl-1;
}

/* LLayer Layout */
.llayer-container {
  @apply w-full overflow-y-scroll;
}

.llayer-content {
  @apply sm:rounded-sm;
}

.llayer-section {
  @apply rounded-sm space-y-2 px-2;
}

.llayer-main {
  @apply py-2 w-full mb-2;
}

/* Destination Section */
.destination-container {
  @apply mx-2 border-2 border-gray-300 rounded shadow p-4;
}

.destination-mllp {
  @apply z-auto grid grid-cols-8 gap-4;
}

.destination-tunnel {
  @apply col-span-2;
}

.destination-ip {
  @apply col-span-4 mt-2;
}

.destination-port {
  @apply col-span-1 mt-2;
}

.destination-s3 {
  @apply flex mt-4 items-center;
}

.destination-s3-inputs {
  @apply flex-grow space-x-4 flex w-full;
}

/* PipeDream Section */
.pipedream-container {
  @apply relative border border-gray-300 rounded-sm px-2 py-2 my-4 shadow-sm;
}

.pipedream-label {
  @apply absolute rounded-sm -top-4 left-2 -mt-px inline-block px-1 bg-gray-100 dark:bg-gray-800 text-xs font-medium text-gray-600 dark:text-gray-200;
}

.pipedream-input-wrapper {
  @apply mt-3 flex;
}

.pipedream-input-container {
  @apply text-xl text-gray-200 flex-1 inline;
}

.pipedream-input {
  @apply block w-full bg-transparent focus:ring-cyan-500 focus:border-cyan-500 rounded-md sm:text-sm border-gray-300 dark:border-gray-700;
}

/* HTTP Warning */
.http-warning {
  @apply pb-2;
}

.http-warning-text {
  @apply text-xl text-gray-200 px-4 mx-1 py-6 my-4 border-4 border-orange-200 rounded;
}

.http-warning-icon {
  @apply h-12 w-12 inline text-orange-300 rounded;
}

/* Auth Header */
.auth-header-container {
  @apply relative border border-cyan-300 col-span-2 rounded-sm px-3 mt-4 shadow-sm;
}

.auth-header-label {
  @apply absolute rounded-sm bg-gray-100 -top-4 left-2 -mt-px inline-block px-1 dark:bg-gray-800 text-xs font-medium dark:text-gray-200;
}

.auth-header-content {
  @apply mt-1 flex overflow-auto;
}

.auth-header-text {
  @apply text-sm dark:text-cyan-400 text-gray-600 py-2;
}

/* Message Preview */
.preview-container {
  @apply relative border-gray-300 border-2 mx-2 px-4 py-2 col-span-2 bg-gray-200 rounded dark:bg-gray-800 shadow;
}

.preview-container-small {
  @apply relative border-gray-300 border mt-6 px-2 py-2 col-span-2 rounded;
}

.preview-label {
  @apply absolute -top-4 left-2 -mt-px inline-block px-1 dark:bg-gray-800 text-lg font-medium text-gray-600 dark:text-gray-200 bg-gray-100 rounded;
}

.preview-content {
  @apply mt-1 flex;
}

.preview-text {
  @apply text-sm dark:text-cyan-500 text-gray-600 py-2 text-wrap;
}

/* FHIR Bundle */
.fhir-container {
  @apply relative border border-cyan-300 col-span-2 rounded-sm px-3 mt-4 w-full shadow-sm;
}

.fhir-label {
  @apply absolute -top-4 left-2 -mt-px rounded-sm inline-block px-1 dark:bg-gray-800 text-xs font-medium dark:text-gray-200 bg-gray-100;
}

.fhir-content {
  @apply mt-1 flex;
}

.fhir-text {
  @apply text-sm dark:text-cyan-400 text-gray-600 p-2;
}
.workflow-card {
  @apply relative h-32 dark:text-gray-200 text-gray-600 dark:bg-gray-800 pt-3 px-3 pb-4 sm:pt-3 sm:px-3 rounded overflow-hidden hover:border-cyan-300 border-2 border-gray-400;
}

.workflow-card-icon-wrapper {
  @apply absolute pt-0.5;
}

.workflow-card-icon {
  @apply h-8 w-8 text-cyan-400 rounded;
}

.workflow-card-title-container {
  @apply cursor-pointer pl-7 mr-5;
}

.workflow-card-title {
  @apply text-lg font-semibold text-gray-500 dark:text-gray-200 truncate pr-2;
}

.workflow-card-delete-btn {
  @apply absolute top-0 right-0 m-2 border-gray-400 border-2 hover:bg-pink-500 hover:text-white hover:border-pink-500 text-gray-400 font-bold text-xs py-0.5 px-2 rounded focus:outline-none;
}

.workflow-card-trigger {
  @apply text-sm ml-0 pl-0 w-full pr-1 text-cyan-400 dark:text-gray-200 truncate;
}

.workflow-card-stats-container {
  @apply absolute right-2 bottom-2 w-fit border-2 border-cyan-400 rounded px-2 pl-2 flex items-baseline text-center;
}

.workflow-card-success-count {
  @apply text-xl font-semibold text-green-400;
}

.workflow-card-success-icon {
  @apply self-center flex-shrink-0 h-5 w-5 text-green-400;
}

.workflow-card-failure-count {
  @apply text-xl ml-0 font-semibold text-red-300 pl-2;
}

.workflow-card-failure-count-number {
  @apply text-xl font-semibold text-red-500;
}

.workflow-card-failure-icon {
  @apply self-center flex-shrink-0 h-5 w-5 text-red-400;
}

/* Workflows Grid Container */
.workflows-grid {
  @apply w-full animate-color-change-2x dark:animate-color-change-2x-dark pt-2 px-2 grid gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6;
}

.workflows-empty-state {
  @apply text-2xl col-span-6 text-center text-gray-300 ~pt-24/48;
}

.workflows-empty-state-text {
  @apply ~px-32/60;
}

/* Workflow Events View */
.workflow-events-sidebar {
  @apply flex-shrink-0 h-full bg-gray-800;
}

.workflow-events-sidebar-content {
  @apply bg-gray-800 border-t-purple-500 border-b-cyan-500 dark:bg-cyan-400 min-w-40 max-w-48 border-r-2 border-r-purple-600 h-full;
}

.workflow-events-list {
  @apply w-full sm:px-2 border-2 dark:border-gray-600 border-gray-300 rounded overflow-y-auto;
}

.workflow-events-empty-state {
  @apply text-center text-gray-600 dark:text-gray-200 text-xl py-24;
}

.workflow-event-item {
  @apply relative py-4 mx-4;
}

.workflow-event-separator {
  @apply absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300 text-white dark:bg-gray-600;
}

.workflow-event-icon-container {
  @apply h-8 w-8 rounded-full flex items-center justify-center text-white ring-4 ring-gray-300 dark:ring-gray-600;
}

.workflow-event-details {
  @apply min-w-0 flex-1 pt-1.5 flex justify-between space-x-1;
}

.workflow-event-message {
  @apply text-sm pl-2 text-gray-600 dark:text-gray-300;
}

.workflow-event-timestamp {
  @apply text-sm whitespace-nowrap text-gray-500 dark:text-gray-500;
}

/* Loading State */
.workflows-loading {
  @apply items-center animate-pulse p-48 sm:p-24 md:p-32 lg:p-48 h-screen w-screen;
}

.workflows-loading-logo {
  @apply h-24 mx-auto w-auto;
}

.workflows-loading-title {
  @apply mt-0 text-center text-2xl font-bold text-gray-600 dark:text-gray-200;
}

.workflows-loading-version {
  @apply inline align-middle text-xs tracking-tight text-cyan-300 pl-1;
}

/* Main Container */
.workflows-main-container {
  @apply flex h-full w-full;
}

.workflows-sidebar {
  @apply bg-gray-700/70 border-t-purple-500 border-b-cyan-500 dark:bg-cyan-400 min-w-36 max-w-44 border-r-2 border-r-purple-600 h-full;
}

.workflows-sidebar-content {
  @apply w-full justify-center h-full py-2;
}
/* Account Type Dropdown Styles */
.account-dropdown-container {
  @apply relative;
}

.account-dropdown-button {
  @apply bg-gray-800 align-middle relative w-full border border-cyan-600 rounded-md shadow-sm 
         pl-3 pr-5 py-2 text-left cursor-default 
         focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm;
}

.account-dropdown-button-text {
  @apply block align-middle;
}

.account-dropdown-button-icon-wrapper {
  @apply absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none;
}

.account-dropdown-button-icon {
  @apply h-5 w-5 text-gray-400;
}

.account-dropdown-options {
  @apply align-middle w-full bg-gray-800 shadow-lg max-h-60 rounded-md py-1 
         text-base ring-1 ring-black ring-opacity-5 overflow-auto 
         focus:outline-none sm:text-sm;
}

.account-dropdown-option {
  @apply cursor-default select-none relative py-2 pl-3 pr-9;
}

.account-dropdown-option-active {
  @apply text-white bg-cyan-600;
}

.account-dropdown-option-inactive {
  @apply text-gray-600;
}

.account-dropdown-option-text {
  @apply block truncate;
}

.account-dropdown-option-text-selected {
  @apply font-semibold;
}

.account-dropdown-option-text-unselected {
  @apply font-normal;
}

.account-dropdown-check-icon-wrapper {
  @apply absolute inset-y-0 right-0 flex items-center pr-4;
}

.account-dropdown-check-icon-active {
  @apply text-white;
}

.account-dropdown-check-icon-inactive {
  @apply text-cyan-600;
}
/* User Permissions Modal Styles */
.permissions-modal-container {
  @apply fixed z-10 inset-0 overflow-y-auto;
}

.permissions-modal-wrapper {
  @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
}

.permissions-modal-overlay {
  @apply fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
}

.permissions-modal-content {
  @apply relative inline-block align-bottom bg-white rounded-lg px-2 pt-2 pb-4 text-left 
         overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle 
         sm:max-w-lg sm:w-full;
}

.permissions-modal-header {
  @apply px-1 pb-6 inline-flex;
}

.permissions-modal-header-icon-wrapper {
  @apply absolute top-4 left-4 px-1 inline;
}

.permissions-modal-header-icon {
  @apply h-10 w-10 text-gray-500 bg-white rounded-md inline;
}

.permissions-modal-header-title {
  @apply text-xl font-medium text-gray-500 inline;
}

.permissions-modal-body {
  @apply mt-2 sm:mt-6 border border-gray-300 bg-white p-1 rounded-md shadow-sm;
}

.permissions-modal-grid {
  @apply sm:grid sm:grid-cols-12 sm:gap-1 bg-white p-1;
}

.permissions-modal-label {
  @apply col-span-3;
}

.permissions-modal-toggle-wrapper {
  @apply col-span-9 text-xs;
}

.permissions-modal-workflows-toggle {
  @apply col-span-2 text-xs;
}

.permissions-modal-footer {
  @apply mt-5 sm:mt-6;
}

.permissions-modal-close-button {
  @apply mt-3 w-full inline-flex justify-center rounded-md border border-cyan-300 
  shadow-sm px-4 py-2 bg-cyan-400 text-base font-medium text-white hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-cyan-500 sm:mt-0 sm:col-start-1 sm:text-sm;
}
