/* Dashboard Styles using Tailwind @apply */

/* Main Container Styles */
.dashboard-container {
  @apply flex h-full bg-gray-700/70 w-full;
}

/* Sidebar Styles */
.dashboard-sidebar {
  @apply bg-gray-700/70 border-t-purple-500 border-b-cyan-500 
         dark:bg-cyan-400 min-w-36 max-w-44 
         border-r-2 border-r-purple-600 h-full;
}

.dashboard-sidebar-title {
  @apply text-gray-200 text-center p-1 border-b-gray-300 border-b-2;
}

.dashboard-sidebar-chart-buttons {
  @apply w-full h-full text-center;
}

.dashboard-chart-button {
  @apply hover:text-cyan-300 py-1 px-4;
}

.dashboard-chart-button-active {
  @apply text-purple-500;
}

/* Main Content Styles */
.dashboard-main-content {
  @apply text-gray-200 w-full h-full 
         animate-color-change-2x dark:animate-color-change-2x-dark;
}

/* Loading State Styles */
.dashboard-loading-container {
  @apply justify-center items-center 
         pt-12 sm:pt-12 md:pt-24 lg:pt-32 xl:pt-48;
}

.dashboard-loading-content {
  @apply items-center animate-pulse;
}

.dashboard-loading-logo {
  @apply h-24 mx-auto w-auto;
}

.dashboard-loading-text {
  @apply mt-0 text-center text-2xl font-bold text-gray-200;
}

/* Empty State Styles */
.dashboard-empty-state {
  @apply text-center text-gray-600 dark:text-gray-200;
}

.dashboard-empty-state-title {
  @apply text-2xl sm:pt-14 md:pt-24 lg:pt-32;
}

.dashboard-empty-state-description {
  @apply py-1;
}
