/* Layout */
.tunnels-container {
  @apply h-full flex w-full bg-gray-700/70;
}

/* Sidebar */
.sidebar {
  @apply bg-gray-700/70 border-t-purple-500 border-b-cyan-500 dark:bg-cyan-400 min-w-32 max-w-36 border-r-2 border-r-purple-600 h-full;
}

.sidebar-content {
  @apply items-start place-items-start px-2 space-y-1 py-1;
}

.sidebar-buttons {
  @apply min-w-36 max-w-44;
}

/* Loading State */
.loading-container {
  @apply items-center animate-pulse p-48 sm:p-24 md:p-32 lg:p-48 h-screen w-screen;
}

.loading-logo {
  @apply h-24 mx-auto w-auto;
}

.loading-title {
  @apply mt-0 text-center text-2xl font-bold text-gray-600 dark:text-gray-200;
}

.loading-version {
  @apply inline align-middle text-xs tracking-tight text-cyan-300 pl-1;
}

/* No Environment Message */
.no-env-container {
  @apply w-full px-4;
}

.no-env-message {
  @apply bg-white dark:bg-gray-800 rounded-lg shadow p-6 text-center;
}

.no-env-title {
  @apply text-3xl font-bold text-gray-900 dark:text-gray-100;
}

.no-env-text {
  @apply mt-2 text-lg text-gray-600 dark:text-gray-300;
}
.tunnel-card-connect {
  @apply relative cursor-auto;
}

.tunnel-action-button-back {
  @apply absolute top-0 left-0 m-2 hover:text-purple-500 text-gray-600 font-bold text-xs py-1 px-2;
}

/* New Tunnel Form */
.new-tunnel-container {
  @apply w-full animate-color-change-2x dark:animate-color-change-2x-dark sm:pt-5 px-2 h-full;
}

.new-tunnel-form {
  @apply cursor-pointer w-full;
}

.form-section {
  @apply py-2;
}

.form-button-container {
  @apply pt-1 pb-2 w-full;
}

.create-button {
  @apply w-full bg-cyan-300 hover:bg-cyan-400 text-white font-bold py-2 px-4 rounded;
}

.disabled-button {
  @apply w-full bg-gray-500 hover:bg-cyan-100 text-white font-bold py-2 px-4 rounded;
}

/* Tunnels Grid */
.tunnels-grid {
  @apply p-4 grid grid-cols-3 gap-2 sm:grid-cols-2 lg:grid-cols-3 animate-color-change-2x dark:animate-color-change-2x-dark w-full;
}

/* Tunnel Card */
.tunnel-card {
  @apply relative dark:bg-gray-800 border-2 dark:border-gray-700 h-60 rounded;
}

.tunnel-card-content {
  @apply cursor-auto px-2;
}

.tunnel-title {
  @apply text-center text-2xl font-semibold text-gray-600 dark:text-gray-100 truncate;
}

.tunnel-action-button {
  @apply absolute top-0 m-2 hover:text-purple-500 text-gray-600 font-bold text-xs py-1 px-2;
}

.tunnel-action-button-key {
  @apply right-8;
}

.tunnel-action-button-delete {
  @apply right-0 hover:text-pink-500;
}

.tunnel-icon {
  @apply h-6 w-6;
}

.tunnel-state {
  @apply text-center text-xs text-cyan-400;
}

.tunnel-info-section {
  @apply divide-y divide-solid;
}

.tunnel-info-content {
  @apply text-center;
}

.tunnel-info-text {
  @apply text-xs text-gray-500 dark:text-gray-200;
}

.tunnel-info-value {
  @apply text-cyan-400;
}

.tunnel-details {
  @apply text-xs text-center text-gray-600 dark:text-gray-100;
}

.tunnel-details-value {
  @apply text-gray-500 dark:text-gray-200 text-sm;
}

.tunnel-protocol-text {
  @apply text-xs text-center text-gray-600 dark:text-gray-100;
}

.tunnel-protocol-value {
  @apply text-gray-500 dark:text-gray-200 text-sm;
}

.tunnel-psk {
  @apply text-xs text-gray-500 dark:text-gray-200 bg-red-800;
}

.tunnel-psk-value {
  @apply text-gray-500 dark:text-gray-200 text-xs;
}
/* TunnelConnect Styles */
.tunnel-connect-container {
  @apply items-center text-center w-72 text-sm;
}

.tunnel-connect-header {
  @apply text-center items-center text-sm font-medium text-gray-600 dark:text-gray-200 inline;
}

.tunnel-connect-title {
  @apply text-center text-2xl font-semibold text-gray-600 dark:text-gray-100 truncate;
}

.tunnel-connect-back {
  @apply absolute top-0 right-8 m-2 hover:text-purple-500 text-gray-600 font-bold text-xs;
}
.tunnel-connect-delete {
  @apply text-gray-500 text-xs py-0 px-0 absolute right-0 top-0 m-2;
}
.tunnel-connect-delete-icon {
  @apply h-6 w-6 inline;
}

.tunnel-connect-back-icon {
  @apply h-6 w-6 inline;
}

/* Advanced Config Section */
.advanced-config {
  @apply pb-2 pt-2 text-xs;
}

.advanced-config-title {
  @apply text-lg pt-1 text-center text-gray-600 dark:text-gray-200;
}

.advanced-config-button {
  @apply mt-2 w-full inline bg-cyan-400 hover:bg-cyan-300 text-gray-600 dark:text-gray-200 font-bold py-2 px-4;
}

/* Simple Config Section */
.simple-config {
  @apply p-2 text-gray-600 dark:text-gray-200 text-sm w-72;
}

.simple-config-title {
  @apply pt-1;
}

.simple-config-description {
  @apply pt-4 text-gray-600 dark:text-gray-200 inline text-sm text-wrap;
}

.simple-config-button-wrapper {
  @apply text-sm pt-1 text-cyan-400 inline;
}

.simple-config-button {
  @apply mt-2 text-sm inline border-2 border-gray-500 hover:bg-cyan-400 text-gray-600 dark:text-gray-200 px-1 rounded;
}
