.theme-toggler-button {
  @apply p-2 rounded-full focus:outline-none;
}

.theme-icon-light {
  @apply h-6 w-8 text-gray-500 hover:text-yellow-500;
}

.theme-icon-dark {
  @apply h-6 w-8 text-gray-500 hover:text-gray-500;
}

.theme-icon-system {
  @apply h-6 w-8 text-gray-500 hover:text-purple-400;
}
/* Navigation Container */
.nav-container {
  @apply bg-transparent dark:bg-gray-700 w-full border-b-2 border-b-purple-500;
}

.nav-wrapper {
  @apply w-full mx-0 px-4;
}

.nav-content {
  @apply flex items-center justify-between h-10 pr-2;
}

/* Logo and Branding */
.nav-logo-container {
  @apply flex items-center;
}

.nav-logo {
  @apply h-10 inline pr-1;
}

.nav-logo-text {
  @apply inline align-middle text-3xl text-gray-600 dark:text-gray-100;
}

.nav-version-badge {
  @apply rounded-sm text-white text-2xs align-sub font-mono font-light 
         inline mx-1 bg-purple-500 py-0.5 px-1;
}

/* Desktop Navigation */
.nav-desktop-menu {
  @apply hidden md:block;
}

.nav-desktop-links {
  @apply ml-10 flex items-baseline space-x-4;
}

.nav-link {
  @apply px-3 py-1 text-lg font-medium;
}

.nav-link-current {
  @apply border-b-cyan-400 border-b-2 text-gray-600 dark:text-white;
}

.nav-link-inactive {
  @apply text-gray-600 dark:text-white hover:border-b-cyan-300 hover:border-b-2;
}

.nav-link-icon {
  @apply h-6 w-6 inline pb-1;
}

/* Desktop Right Section */
.nav-desktop-right {
  @apply hidden md:block;
}

.nav-docs-container {
  @apply flex font-medium text-gray-600 dark:text-white cursor-pointer;
}

.nav-docs-text {
  @apply text-gray-600 dark:text-gray-200 
         hover:text-purple-400 dark:hover:text-cyan-400 
         px-4 py-2;
}

.nav-profile-button {
  @apply bg-transparent dark:bg-gray-700 rounded-full 
         border-2 border-gray-200 dark:border-gray-600 
         text-gray-500 focus:outline-none;
}

.nav-profile-image {
  @apply h-8 w-8 hover:w-10 hover:h-10 rounded-full;
}

/* Dropdown Menu */
.nav-dropdown-menu {
  @apply min-w-36 origin-top-right absolute right-0 mt-2 w-auto 
         rounded-sm border border-gray-300 dark:border-gray-800 
         shadow-lg py-1 bg-gray-100 dark:bg-gray-700 
         ring-1 ring-black ring-opacity-5 focus:outline-none 
         text-center;
}

.nav-dropdown-item {
  @apply cursor-pointer block py-2 truncate text-sm 
         dark:text-gray-100 w-full;
}

.nav-dropdown-item-active {
  @apply bg-cyan-400;
}

/* Mobile Menu Button */
.nav-mobile-button {
  @apply bg-cyan-400 inline-flex items-center justify-center 
         p-2 rounded-md text-cyan-200 hover:text-white 
         hover:bg-cyan-300 hover:bg-opacity-75 
         focus:outline-none focus:ring-2 focus:ring-offset-2 
         focus:ring-offset-cyan-400 focus:ring-white;
}

/* Mobile Navigation Panel */
.nav-mobile-panel {
  @apply md:hidden z-50;
}

.nav-mobile-links {
  @apply px-2 pt-2 pb-3 space-y-1 sm:px-3;
}

.nav-mobile-link {
  @apply block px-3 py-2 rounded-md text-base font-medium;
}

.nav-mobile-link-current {
  @apply bg-cyan-400 text-white;
}

.nav-mobile-link-inactive {
  @apply text-white hover:bg-cyan-300 hover:bg-opacity-75;
}

/* Mobile User Section */
.nav-mobile-user-section {
  @apply pt-4 pb-3 border-t border-cyan-500;
}

.nav-mobile-user-info {
  @apply flex items-center px-5;
}

.nav-mobile-user-name {
  @apply text-base font-medium text-white;
}

.nav-mobile-user-email {
  @apply text-sm font-medium text-cyan-300;
}

.nav-mobile-submenu {
  @apply mt-3 px-2 space-y-1 z-50;
}

.nav-mobile-submenu-item {
  @apply block px-3 py-2 rounded-md text-base font-medium 
         text-white hover:bg-cyan-300 hover:bg-opacity-75;
}
.footer-container {
  @apply border-t-cyan-400 border-t-2 
         bg-gray-200 dark:bg-gray-800 
         w-full mx-auto py-4 px-4 
         overflow-hidden sm:px-6 lg:px-8 
         mt-0;
}

.footer-content {
  @apply my-5;
}

.footer-text {
  @apply text-center text-base leading-6 
         text-gray-600 dark:text-gray-100;
}
.action-button-container {
  @apply inline w-full py-1 px-3 hover:text-purple-400 text-gray-100;
}

.action-button {
  @apply whitespace-nowrap focus:outline-none;
}

.action-button-icon {
  @apply inline h-5 w-5 mr-1;
}
.env-dropdown-container {
  @apply relative z-40;
}

.env-dropdown-button {
  @apply animate-color-change-2x dark:animate-color-change-2x-dark 
         text-gray-600 dark:text-gray-100 
         align-middle relative w-full 
         border border-gray-600 rounded-md 
         shadow-sm pl-3 pr-8 py-2 
         text-left cursor-default 
         focus:outline-none focus:ring-1 
         focus:ring-cyan-300 focus:border-cyan-300 
         sm:text-sm;
}

.env-dropdown-button-text {
  @apply block align-middle text-sm 
         text-gray-600 dark:text-gray-100;
}

.env-dropdown-button-icon-wrapper {
  @apply absolute inset-y-0 right-0 
         flex items-center pr-2 
         pointer-events-none;
}

.env-dropdown-button-icon {
  @apply h-5 w-5 text-gray-600 dark:text-gray-100;
}

.env-dropdown-options {
  @apply align-middle w-full 
         animate-color-change-2x z-50 
         dark:animate-color-change-2x-dark 
         absolute shadow-lg max-h-60 
         rounded-md py-1 text-base 
         ring-1 ring-black ring-opacity-5 
         overflow-visible focus:outline-none 
         sm:text-sm;
}

.env-dropdown-option {
  @apply cursor-default select-none 
         relative py-2 pl-3 pr-9;
}

.env-dropdown-option-active {
  @apply text-gray-600 dark:text-gray-100 bg-cyan-400;
}

.env-dropdown-option-inactive {
  @apply text-gray-600 dark:text-gray-100;
}

.env-dropdown-option-text {
  @apply block truncate;
}

.env-dropdown-option-text-selected {
  @apply font-semibold;
}

.env-dropdown-option-text-unselected {
  @apply font-normal;
}

.env-dropdown-option-check-wrapper {
  @apply absolute inset-y-0 right-0 
         flex items-center pr-4;
}

.env-dropdown-option-check-icon {
  @apply h-5 w-5;
}

.env-dropdown-option-check-icon-active {
  @apply text-gray-600 dark:text-gray-100;
}

.env-dropdown-option-check-icon-inactive {
  @apply text-cyan-400;
}
.env-select-modal-container {
  @apply fixed z-30 inset-0 overflow-y-visible text-sm;
}

.env-select-modal-wrapper {
  @apply flex items-end justify-center 
         min-h-screen pt-4 px-4 pb-20 
         text-center sm:block sm:p-0;
}

.env-select-modal-overlay {
  @apply fixed inset-0 bg-gray-500 
         bg-opacity-75 transition-opacity;
}

.env-select-modal-spacer {
  @apply hidden sm:inline-block 
         sm:align-middle sm:h-screen;
}

.env-select-modal-content {
  @apply relative inline-block align-bottom 
         animate-color-change-2x 
         dark:animate-color-change-2x-dark 
         rounded-lg px-4 pt-5 text-left 
         overflow-visible shadow-xl transform 
         transition-all sm:my-8 sm:align-middle 
         sm:max-w-lg sm:w-full sm:p-6;
}

.env-select-modal-header {
  @apply px-1 inline-flex;
}

.env-select-modal-icon {
  @apply h-8 w-8 text-gray-600 
         dark:text-gray-200 
         animate-color-change-2x 
         dark:animate-color-change-2x-dark 
         rounded-md;
}

.env-select-modal-title {
  @apply text-xl font-medium pl-2 
         text-gray-600 dark:text-gray-200;
}

.env-select-modal-close-button {
  @apply w-full inline-flex justify-center 
         rounded-md border hover:bg-cyan-300 
         shadow-sm px-4 py-2 text-base 
         font-medium text-gray-600 
         dark:text-gray-100 bg-gray-100 
         dark:bg-gray-800 focus:outline-none 
         focus:ring-2 focus:ring-offset-2 
         focus:ring-cyan-400 sm:mt-0 
         sm:col-start-1 sm:text-sm;
}
.inner-nav-button-container {
  @apply mx-1;
}

.inner-nav-button {
  @apply inline text-gray-100 whitespace-nowrap w-full py-1 hover:text-purple-300 font-medium focus:outline-none;
}

.inner-nav-button-icon {
  @apply pr-1 h-6 w-6 inline;
}
.inner-nav-button-selected-container {
  @apply px-1;
}

.inner-nav-button-selected {
  @apply inline w-full px-0 py-1 font-medium text-purple-400 focus:outline-none;
}

.inner-nav-button-selected-icon {
  @apply pr-1 h-6 w-6 inline;
}
.page-heading-container {
  @apply mx-auto py-4 px-6;
}

.page-heading-text {
  @apply leading-6 font-semibold text-gray-100;
}
.workspace-dropdown-container {
  @apply relative z-10;
}

.workspace-dropdown-button {
  @apply animate-color-change-2x dark:animate-color-change-2x-dark 
         text-gray-600 dark:text-gray-100 
         align-middle relative w-full 
         border border-gray-600 rounded-md 
         shadow-sm pl-3 pr-8 py-2 
         text-left cursor-default 
         focus:outline-none focus:ring-1 
         focus:ring-cyan-300 focus:border-cyan-300 
         sm:text-sm;
}

.workspace-dropdown-button-text {
  @apply block align-middle text-sm text-gray-600 dark:text-gray-100 overflow-x-hidden;
}

.workspace-dropdown-button-icon-wrapper {
  @apply absolute inset-y-0 right-0 
         flex items-center pr-2 
         pointer-events-none;
}

.workspace-dropdown-button-icon {
  @apply h-5 w-5 text-gray-600 dark:text-gray-100;
}

.workspace-dropdown-options {
  @apply align-middle w-full 
         animate-color-change-2x 
         dark:animate-color-change-2x-dark 
         absolute shadow-lg max-h-60 
         rounded-md py-1 text-base 
         ring-1 ring-black ring-opacity-5 
         overflow-hidden focus:outline-none 
         sm:text-sm;
}

.workspace-dropdown-option {
  @apply cursor-default select-none 
         relative py-2 pl-3 pr-9;
}

.workspace-dropdown-option-active {
  @apply text-gray-600 dark:text-gray-100 bg-cyan-400;
}

.workspace-dropdown-option-inactive {
  @apply text-gray-600 dark:text-gray-100;
}

.workspace-dropdown-option-text {
  @apply block truncate;
}

.workspace-dropdown-option-text-selected {
  @apply font-semibold;
}

.workspace-dropdown-option-text-unselected {
  @apply font-normal;
}

.workspace-dropdown-option-check-wrapper {
  @apply absolute inset-y-0 right-0 
         flex items-center pr-4;
}

.workspace-dropdown-option-check-icon {
  @apply h-5 w-5;
}

.workspace-dropdown-option-check-icon-active {
  @apply text-gray-600 dark:text-gray-100;
}

.workspace-dropdown-option-check-icon-inactive {
  @apply text-cyan-400;
}
.workspace-select-modal-container {
  @apply fixed z-10 inset-0 overflow-y-auto text-sm;
}

.workspace-select-modal-wrapper {
  @apply flex items-end justify-center 
         min-h-screen pt-4 px-4 pb-20 
         text-center sm:block sm:p-0;
}

.workspace-select-modal-overlay {
  @apply fixed inset-0 bg-gray-500 
         bg-opacity-75 transition-opacity;
}

.workspace-select-modal-spacer {
  @apply hidden sm:inline-block 
         sm:align-middle sm:h-screen;
}

.workspace-select-modal-content {
  @apply relative inline-block align-bottom 
         animate-color-change-2x 
         dark:animate-color-change-2x-dark 
         rounded-lg px-4 pt-5 text-left 
         overflow-hidden shadow-xl transform 
         transition-all sm:my-8 sm:align-middle 
         sm:max-w-lg sm:w-full sm:p-6;
}

.workspace-select-modal-header {
  @apply px-1 inline-flex;
}

.workspace-select-modal-icon {
  @apply h-8 w-8 text-gray-600 
         dark:text-gray-200 
         animate-color-change-2x 
         dark:animate-color-change-2x-dark 
         rounded-md;
}

.workspace-select-modal-title {
  @apply text-xl font-medium pl-2 
         text-gray-600 dark:text-gray-200;
}

.workspace-select-modal-close-button {
  @apply w-full inline-flex justify-center 
         rounded-md border hover:bg-cyan-300 
         shadow-sm px-4 py-2 text-base 
         font-medium text-gray-600 
         dark:text-gray-100 bg-gray-100 
         dark:bg-gray-800 focus:outline-none 
         focus:ring-2 focus:ring-offset-2 
         focus:ring-cyan-400 sm:mt-0 
         sm:col-start-1 sm:text-sm;
}
/* Loading State */
.loading-container {
  @apply items-center animate-pulse p-48 sm:p-24 md:p-32 lg:p-48 h-screen w-screen;
}

.loading-logo {
  @apply h-24 mx-auto w-auto;
}

.loading-title {
  @apply mt-0 text-center text-2xl font-bold text-gray-600 dark:text-gray-200;
}

.loading-version {
  @apply inline align-middle text-xs tracking-tight text-cyan-300 pl-1;
}
.workspace-modal {
  @apply fixed inset-0 z-50 overflow-y-auto;
}

.workspace-modal-container {
  @apply flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0;
}

.workspace-modal-overlay {
  @apply fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
}

.workspace-modal-spacer {
  @apply hidden sm:inline-block sm:h-screen sm:align-middle;
}

.workspace-modal-content {
  @apply relative inline-block transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle;
}

.workspace-modal-header {
  @apply flex items-center space-x-3 mb-6;
}

.workspace-modal-icon {
  @apply h-6 w-6 text-purple-500;
}

.workspace-modal-title {
  @apply text-lg font-medium leading-6 text-gray-900 dark:text-white;
}

.workspace-modal-dropdown {
  @apply mt-4;
}

.workspace-modal-footer {
  @apply mt-6 flex justify-end;
}

.workspace-modal-close-button {
  @apply inline-flex justify-center rounded-md border border-transparent bg-purple-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 sm:text-sm transition-all;
}
